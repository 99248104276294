import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useQuery from "../../utils/UseQuery";
import DummiesApi from "../../api/dummies_api";
import more from "./../../assets/more.png";

import "./ins_new.scss";
import {
  TextFieldStyleDummies,
  TextFieldStyleDummies2,
} from "../../utils/TextFieldStyle/TextFieldStyle";
import { ButtonIns, ButtonStyle } from "../../utils/Buttons/Buttons";
import logoIns from "../../assets/logoins.png";
import { infoMessage, successMessage } from "../../api/errorHandler";

import {
  Checkbox,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  ThemeProvider,
} from "@mui/material";

import { FormGroup, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles({
  select: {
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
  icon: {
    fill: "white",
  },
  root: {
    color: "white",
  },
});

function InsFormPay(props) {
  const classes = useStyles();
  let history = useHistory();
  var [bgColor, setBgColor] = useState(["#008946", "#006b39"]);
  const api = new DummiesApi();
  const query = useQuery();
  const filledFormId = query.get("filledFormId");

  const [value, setValue] = useState("");

  async function nextScreen() {
    // successMessage("Correo enviado!");
    if (value !== "bio") {
      infoMessage("Medio no soportado");
      return;
    }
    window.location =
      "https://dev.registration.anteia.co/avisosPay?code=insDemo15320789&filledFormId=" +
      filledFormId;
  }

  function createDropdown() {
    let items = [
      <MenuItem key={1} value={"bio"}>
        Pago Biométrico
      </MenuItem>,
      <MenuItem key={2} value={"transf_bancaria"}>
        Transferencia Bancaria
      </MenuItem>,
      <MenuItem key={3} value={"tarj_credito"}>
        Tarjeta de Crédito
      </MenuItem>,
    ];
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">Medios de pago</p>
        <FormGroup
          sx={{
            color: "white",
            borderColor: "white",
            outlineColor: "white",
            accentColor: "white",
          }}
        >
          <Select
            className={classes.select}
            sx={{
              color: "white",
              borderColor: "white",
              outlineColor: "white",
              accentColor: "white",
            }}
            value={value}
            inputProps={{
              classes: {
                icon: classes.icon,
                root: classes.root,
              },
            }}
            onChange={(e) => {
              setValue(e.target.value);
              console.log(e.target.value);
            }}
          >
            {items}
          </Select>
        </FormGroup>
      </div>
    );
  }

  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      {/* Main Col */}
      <div className="col" style={{ marginLeft: "10vw", marginRight: "10vw" }}>
        {/* titlesRow */}
        <div className="row" style={{ marginTop: "10vh", marginBottom: "5vh" }}>
          <p className="txt-title">Selecciona tu método de pago</p>
        </div>

        {/* dropdown */}
        <div style={{ marginBottom: "10vh" }}>{createDropdown()}</div>

        {/* Button Next */}
        <div className="row btn-more">
          <div className="col">
            <ButtonStyle
              text="Aceptar"
              onClick={() => nextScreen()}
              width="177px"
              height="5vh"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsFormPay;
