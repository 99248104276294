import React, { useState, useEffect } from "react";

import "react-linear-gradient-picker/dist/index.css";
import "./Degraded.scss";
import img from "./../../assets/logoanteia.png";
import {SliderPicker } from 'react-color'

function Degraded() {
    const [color, setColor] = useState('#ffffff');
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 600px)").matches
  );
  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia("(min-width: 600px)").addListener(handler);
  }, []);
  const handleChange = (color) => {
    setColor(color.hex);
  };
  return (
    <div className="degraded">
      <div className="mini-nav">
        <p>Degraded</p>
      </div>
      <img src={img} alt="logo" />
      <div className="picker">
      <SliderPicker    color={color}
          onChange={handleChange}
    />
      </div>
    </div>
  );
}
export default Degraded;
