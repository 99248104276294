import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useQuery from "../../utils/UseQuery";
import DummiesApi from "../../api/dummies_api";
import more from "./../../assets/more.png";

import "./ins_new.scss";
import {
  TextFieldStyleDummies,
  TextFieldStyleDummies2,
} from "../../utils/TextFieldStyle/TextFieldStyle";
import { ButtonIns, ButtonStyle } from "../../utils/Buttons/Buttons";
import logoIns from "../../assets/logoins.png";
import { infoMessage, successMessage } from "../../api/errorHandler";
import Swal from "sweetalert2";

function InsFormEnd(props) {
  let history = useHistory();
  var [bgColor, setBgColor] = useState(["#008946", "#006b39"]);
  const api = new DummiesApi();
  const query = useQuery();
  const filledFormId = query.get("filledFormId");

  async function nextScreen() {
    infoMessage("Enviando correo...");
    let filledForm = await api.getFilledForm(filledFormId);
    if (!filledForm) {
      infoMessage("Error, su url es incorrecta");
      return;
    }
    let data = filledForm.data;
    console.log(data);
    let fullName = data.name + " " + data.lastName;
    await api.sendEmailIns(
      data.email,
      fullName,
      data.document,
      data.initialDate,
      data.endDate,
      fullName,
      data.document,
      data.monto,
      data.prima
    );
    Swal.close();
    successMessage("Correo enviado!");
  }

  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      {/* Main Col */}
      <div className="col" style={{ marginLeft: "10vw", marginRight: "10vw" }}>
        {/* titlesRow */}
        <div className="row" style={{ marginTop: "5vh", marginBottom: "5vh" }}>
          <div style={{ marginBottom: "7vh" }}>
            <img src={logoIns} />
          </div>
          <p className="txt-label">Hemos enviado a tu correo electrónico</p>
          <p className="txt-welcome">TU SEGURO DE VIAJES</p>
        </div>

        {/* Button Next */}
        <div className="row btn-more">
          <div className="col">
            <ButtonStyle
              text="Finalizar"
              onClick={() => nextScreen()}
              width="177px"
              height="5vh"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsFormEnd;
