import React, { useState } from "react";
import StyleProperties from "../../dtos/StyleProperties";

export interface CurrencyInputProps {
  title: string;
  value: string;
  onChange: (value: string) => void;
  style?: StyleProperties;
  darkMode?: boolean;
  disabled?: boolean;
  currency?: string;
  country?: string;
}

export const CurrencyInput = ({
  title,
  onChange,
  value,
  style,
  darkMode,
  disabled,
  currency,
  country,
}: CurrencyInputProps) => {
  const getColor = () => {
    if (style && style.color) {
      return style.color;
    } else {
      return darkMode ? "white" : "black";
    }
  };

  const getBorderColor = () => {
    if (style && style.borderColor) {
      return style.borderColor;
    } else {
      return darkMode ? "white" : "black";
    }
  };

  const getDefaultMargin = () => {
    if (style && (style.margin || style.marginTop || style.marginBottom)) {
      return {
        margin: style.margin,
        marginTop: style.marginTop,
        marginBottom: style.marginBottom,
      };
    } else {
      return { margin: "15px 0" };
    }
  };

  const handleInputChange = (event: any) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    const formattedValue = formatCurrency(numericValue);
    setFormattedValue(formattedValue);

    onChange(numericValue);
  };

  const formatCurrency = (value: string) => {
    // get numeric value deleting symbols, commas, dots, etc
    const numericValue = parseFloat(value.replace(/[^0-9]/g, "")) || 0;

    if (numericValue === 0) return "";

    const formatted = new Intl.NumberFormat(country ? country : "es-CO", {
      style: "currency",
      currency: currency ? currency : "COP",
      currencyDisplay: "narrowSymbol",
      maximumFractionDigits: 0,
    }).format(numericValue);

    return formatted;
  };

  const [formattedValue, setFormattedValue] = useState(formatCurrency(value));

  return (
    <div className={`w-full flex`} style={getDefaultMargin()}>
      <input
        placeholder={title}
        style={{ ...style, color: getColor(), borderColor: getBorderColor() }}
        className="bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center w-full h-[38px]"
        type="text"
        inputMode="numeric"
        value={formattedValue}
        disabled={disabled}
        onChange={handleInputChange}
      />
    </div>
  );
};
