import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DummiesApi from "../../api/dummies_api";
import { Button, Button3, ButtonIns, ButtonStyle } from "../../utils/Buttons/Buttons";
import { TextFieldStyle } from "../../utils/TextFieldStyle/TextFieldStyle";
import { TextField1 } from "../../Dummies/InputT/TextField1/TextField1";
import { infoMessage, successMessage } from "../../api/errorHandler";
import useQuery from "../../utils/UseQuery";
import { useHistory } from "react-router-dom";
import "./ins_new.scss";
import logoIns from "../../assets/logoins.png";
// import logoIns from "../../assets/logoIns2.png";

function InsIntro(props) {
  let history = useHistory();
  const api = new DummiesApi();
  // var [bgColor, setBgColor] = useState(["#292840", "#566075"]);
  var [bgColor, setBgColor] = useState(["#008946", "#006b39"]);

  const gotoRegister = () => {
    history.push("/insRegister1");
  };

  const gotoForm = () => {
    // var params = window.location.href.split("?")[1];
    history.push("/insFormIntro");
  };

  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div>
        <div className="col" style={{ marginTop: "20vh" }}>
          <img src={logoIns}></img>
          <div style={{ marginTop: "30vh" }}>
            <ButtonStyle
              className="button-custom"
              text="Registrarme"
              width={"60vw"}
              height={"50px"}
              onClick={() => gotoRegister()}
            />
          </div>

          <div style={{ marginTop: "10%", marginBottom: "30%" }}>
            <ButtonStyle
              className="button-custom"
              text="Ingresar"
              width={"60vw"}
              height={"50px"}
              onClick={() => gotoForm()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsIntro;
