import React from "react";
import "./ColorsSystem.scss";
// import { styled } from "@mui/material/styles";

import Grid from "@mui/material/Grid";

function ColorsSystem() {
  const colorline1 = [
    {
      id: 1,
      name: "primary",
      color: "#000000",
    },
    {
      id: 2,
      name: "primary soft",
      color: "#3E2B75",
    },
    {
      id: 3,
      name: "second soft",
      color: "#105469",
    },
  ];
    const colorline2 = [
      {
        id: 1,
        name: "Info",
        color: " #5FAAEC",
      },
      {
        id: 2,
        name: "Success",
        color: "#41CF69",
      },
      {
        id: 3,
        name: "Danger",
        color: "#EA676A",
      },

      {
        id: 4,
        name: "Warming",
        color: " #F8D355",
      },
    ];
     const colorline3 = [
       {
         id: 1,
         name: "White",
         color: "white",
       },
       {
         id: 2,
         name: "Background",
         color: "black",
       },
  

     
    ];
        const colorline4 = [
          {
            id: 1,
            name: "G-10%",
            color: "rgba(62, 43, 117, 0.1)",
          },
          {
            id: 2,
            name: "G-25%",
            color: " rgba(62, 43, 117, 0.25)",
          },
          {
            id: 3,
            name: "G-50%",
            color: "rgba(62, 43, 117, 0.5)",
          },
          {
            id: 4,
            name: "G-75%",
            color: "rgba(62, 43, 117, 0.75)",
          },
          {
            id: 5,
            name: "G-90%",
            color: "rgba(62, 43, 117, 0.9)",
          },
        ];


  return (
    <div className="color-system">
      <div className="mini-nav">
        <p>Colors | Brand & System</p>
      </div>
      <div className='container-colors'>
        <p className='title-p'>Own branding colors</p>

        <Grid container>
          {colorline1.map((i) => (
            <Grid item xs={2.3}>
              <div className="colors">
                <p>{i.name}</p>
                <div
                  style={{
                    backgroundColor: i.color,
                    height: "50px",
                    width: "50px",
                    borderRadius: "4px",
                  }}
                ></div>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid container>
          {colorline2.map((i) => (
            <Grid item xs={2.3}>
              <div className="colors">
                <p>{i.name}</p>
                <div
                  style={{
                    backgroundColor: i.color,
                    height: "50px",
                    width: "50px",
                    borderRadius: "4px",
                  }}
                ></div>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid container>
          {colorline3.map((i) => (
            <Grid item xs={2.3}>
              <div className="colors">
                <p>{i.name}</p>
                <div
                  style={{
                    backgroundColor: i.color,
                    height: "50px",
                    width: "50px",
                    borderRadius: "4px",
                    border: "1px solid gray",
                  }}
                ></div>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid container>
          {colorline4.map((i) => (
            <Grid item xs={2.3}>
              <div className="colors">
                <p>{i.name}</p>
                <div
                  style={{
                    backgroundColor: i.color,
                    height: "50px",
                    width: "50px",
                    borderRadius: "4px",
                    border: "1px solid gray",
                  }}
                ></div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
export default ColorsSystem;
