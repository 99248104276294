import React, { useState } from "react";
import "./TomaBack.scss";
import silueta2 from "./../../assets/silueta2.png";
// import { useHistory } from "react-router-dom";
import VideoMock from "../VideoMock/VideoMock";
import video from "./../../assets/video2.mp4";

function TomaBack(props) {
  const [show2, setShow2] = useState(false);
  // const history = useHistory();
  const NextPage = () => {
    setShow2(true);
  };
  return (
    <>
      {!show2 ? (
        <div className="toma2-style" onClick={() => NextPage()}>
          <p className="txt-1">
            TOMA DE MI <br></br> VEHÍCULO
          </p>
          <p className="txt-2">
            Úbica en el motor la <br></br> plaqueta con el No. de <br></br>{" "}
            serie de tu auto
          </p>
          <img src={silueta2} alt="img" />
          <p className="txt-3">
            Cuando estes listo<br></br>
            para grabar toca la<br></br> pantalla para tomar un<br></br> video
            de tu auto
          </p>
        </div>
      ) : (
        <VideoMock  video={video} setShow2={setShow2} tomaroute={"/verify"} send={props.send}/>
      )}
    </>
  );
}
export default TomaBack;
