import "./faceUsuarioDummy.scss";
import { useEffect, useState } from "react";
import ROSTRO from "../../../assets/rostro.png";
import ROSTROAMARILLO from "../../../assets/rostro amarillo.png";
import ROSTROVERDE from "../../../assets/rostro verde.png";
import BIOBLANCO from "../../../assets/BIOBLANCO.png";
import BIOAMARILLO from "../../../assets/BIOAMARILLO.png";
import BIOVERDE from "../../../assets/BIOVERDE.png";

import { ReactComponent as FLECHAARRIBA } from "../../../assets/flechaArriba.svg";

export const FaceUsuarioDummy = () => {
  const [img1, setImg1] = useState(true);
  const [img2, setImg2] = useState(false);
  const [img3, setImg3] = useState(false);
  const [confirmarDatos, setconfirmarDatos] = useState(false);

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function procesoCara() {
    setImg1(true);
    await delay(3000);
    setImg1(false);
    setImg2(true);
    await delay(3000);
    setImg2(false);
    setImg3(true);
    await delay(3000);
    setImg3(false);
    setconfirmarDatos(true);
  }

  function idDigital() {
    window.location.href = "/identidadDigitalUsuarioDummy";
  }

  useEffect(() => {
    procesoCara();
  }, []);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      {img1 && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(110.38% 98.55% at 100% 1.45%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              position: "absolute",
              top: "1rem",
              right: "1rem",
            }}
          ></button>
          <h3
            style={{
              color: "white",
              fontWeight: "lighter",
              margin: "2rem 2rem 0 2rem",
            }}
          >
            UBICA TU CARA DENTRO DEL ÓVALO
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              height: "20rem",
            }}
          >
            <img
              style={{ position: "absolute", width: "70%" }}
              src={ROSTRO}
              alt="ROSTRO"
            />
            <img
              style={{ position: "absolute", width: "85%" }}
              src={BIOBLANCO}
              alt="BIOBLANCO"
            />
          </div>
          <FLECHAARRIBA />
          <h3 style={{ color: "white", margin: "2rem" }}>
            HASTA QUE EL CONTORNE SEA VERDE
          </h3>
          <h3
            style={{ color: "white", fontWeight: "lighter", margin: "0 2rem" }}
          >
            Sigue la guía en pantalla
          </h3>
        </div>
      )}
      {img2 && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(110.38% 98.55% at 100% 1.45%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              position: "absolute",
              top: "1rem",
              right: "1rem",
            }}
          ></button>
          <h3
            style={{
              color: "white",
              fontWeight: "lighter",
              margin: "2rem 2rem 0 2rem",
            }}
          >
            CONTINUA VIENDO TU PANTALLA
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              height: "20rem",
            }}
          >
            <img
              style={{ position: "absolute", width: "70%" }}
              src={ROSTROAMARILLO}
              alt="ROSTRO"
            />
            <img
              style={{ position: "absolute", width: "85%" }}
              src={BIOAMARILLO}
              alt="BIOBLANCO"
            />
          </div>
          <FLECHAARRIBA />
          <h3 style={{ color: "white", margin: "2rem" }}>
            ADQUIRIENDO TU HUELLA BIOMÉTRICA FACIAL
          </h3>
        </div>
      )}
      {img3 && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(110.38% 98.55% at 100% 1.45%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              position: "absolute",
              top: "1rem",
              right: "1rem",
            }}
          ></button>
          <h3
            style={{
              color: "white",
              fontWeight: "lighter",
              margin: "2rem 2rem 0 2rem",
            }}
          >
            LISTO
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              height: "20rem",
            }}
          >
            <img
              style={{ position: "absolute", width: "70%" }}
              src={ROSTROVERDE}
              alt="ROSTRO"
            />
            <img
              style={{ position: "absolute", width: "70%" }}
              src={BIOVERDE}
              alt="BIOBLANCO"
            />
          </div>
          <FLECHAARRIBA />
          <h3 style={{ color: "white", margin: "2rem" }}>
            FINALIZO LA CAPTURA DE TU HUELLA BIOMÉTRICA
          </h3>
        </div>
      )}
      {confirmarDatos && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(101.01% 98.98% at 100% 98.98%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              position: "absolute",
              top: "1rem",
              right: "1rem",
            }}
          ></button>
          <h4
            style={{
              color: "white",
              fontWeight: "lighter",
              margin: "4rem 4rem 0 4rem",
            }}
          >
            ES TIEMPO DE CONFIRMAR TUS DATOS
          </h4>
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="NOMBRES"
            type="text"
            value="ELIANA"
          />
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="APELLIDOS"
            type="text"
            value="MONTERO SANIN"
          />
          <div style={{ marginTop: "2rem" }}>
            <input id="Natural" type="radio" checked />
            <label style={{ color: "white" }} htmlFor="Natural">
              PERSONA NATURAL
            </label>
          </div>
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="TIPO DE DOCUMENTO"
            type="text"
            value="CEDULA DE CIUDADANIA"
          />
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="No. DE DOCUMENTO"
            type="number"
            value="107410865"
          />
          <h4
            style={{
              color: "white",
              fontWeight: "lighter",
              margin: "1rem 2rem 0 2rem",
            }}
          >
            ¿Todos tus datos son correctos?
          </h4>
          <button
            onClick={idDigital}
            style={{
              marginTop: "2rem",
              color: "white",
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
              borderColor: "black",
              width: "80%",
              height: "3rem",
              borderRadius: "50px",
            }}
          >
            SI
          </button>
          <button
            style={{
              marginTop: "1rem",
              color: "white",
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%)",
              borderColor: "black",
              width: "80%",
              height: "3rem",
              borderRadius: "50px",
            }}
          >
            NO
          </button>
        </div>
      )}
    </div>
  );
};
