import React, { useEffect, useState } from "react";
import { TextTransform } from "./TextTransform";
import StyleProperties from "../../dtos/StyleProperties";

interface CheckBoxSelectProps {
  id: string;
  label: string;
  color: string;
  onChange?: (selected: boolean) => void;
  disabled?: boolean;
  style?: StyleProperties;
  selectedValue?: boolean;
}

export const CheckBoxSelect: React.FC<CheckBoxSelectProps> = ({
  id,
  label,
  color,
  onChange,
  disabled,
  style,
  selectedValue,
}) => {
  const [selected, setSelected] = useState(false);

  const handleCheckChange = () => {
    if (onChange) {
      onChange(!selected);
    }
    setSelected(!selected);
  };

  useEffect(() => {
    if (selectedValue !== undefined) {
      setSelected(selectedValue);
    }
  }, [selectedValue]);

  return (
    <div className="w-full flex flex-row">
      <input
        disabled={disabled}
        type="checkbox"
        id={id}
        name={id}
        value={label}
        onChange={() => {
          handleCheckChange();
        }}
        checked={selected}
      />
      <label
        className="flex flex-row relative cursor-pointer select-none space-x-[12px]"
        htmlFor={id}
      >
        <span className=" h-[20px] w-[20px]">
          {selected ? (
            <svg
              className={`transition h-[20px] w-[20px]`}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.625 8.875L10 12.25L19 3.25"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 10V16.75C19 17.3467 18.7629 17.919 18.341 18.341C17.919 18.7629 17.3467 19 16.75 19H3.25C2.65326 19 2.08097 18.7629 1.65901 18.341C1.23705 17.919 1 17.3467 1 16.75V3.25C1 2.65326 1.23705 2.08097 1.65901 1.65901C2.08097 1.23705 2.65326 1 3.25 1H13.375"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              className={`transition h-[20px] w-[20px]`}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 10V16.75C19 17.3467 18.7629 17.919 18.341 18.341C17.919 18.7629 17.3467 19 16.75 19H3.25C2.65326 19 2.08097 18.7629 1.65901 18.341C1.23705 17.919 1 17.3467 1 16.75V3.25C1 2.65326 1.23705 2.08097 1.65901 1.65901C2.08097 1.23705 2.65326 1 3.25 1H13.375"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </span>
        <span style={style}>
          <TextTransform text={label} style={style} />
        </span>
      </label>
    </div>
  );
};
