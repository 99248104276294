import zIndex from "@mui/material/styles/zIndex";
import { ReactComponent as AnteiaR } from "../../assets/anteiaR.svg";
import { ReactComponent as Paused } from "../../assets/Pausa.svg";

export interface AgentComponentProps {
  menuStatus: "Static" | "Extended" | "Deployed";
  setMenuStatus: Function;
  pauseFunction: Function;
}

export const PauseComponent = (props: AgentComponentProps) => {
  console.log(props.menuStatus);
  const manageToggle = () => {
    switch (props.menuStatus) {
      case "Static":
        props.setMenuStatus("Extended");
        break;
      case "Extended":
        props.setMenuStatus("Static");
        break;
      default:
        props.setMenuStatus("Static");
        break;
    }
  };

  const menu = () => {
    switch (props.menuStatus) {
      case "Static":
        return (
          <div className=''>
            {/* <Paused
            style={{ cursor: "pointer", height: "30px", width: "30px" }}
            onClick={(e) => {
              e.preventDefault();
              manageToggle();
              props.pauseFunction();
            }}
          /> */}
            <img
              src={
                "https://storage.googleapis.com/anteia-cdn/logos/coomuldesa/question.png"
              }
              style={{ cursor: "pointer", height: "30px", width: "30px" }}
              alt='Boton de pausa'
              onClick={(e) => {
                e.preventDefault();
                manageToggle();
                props.pauseFunction();
              }}
            />
          </div>
        );
      case "Extended":
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {/* <AnteiaR style={{ marginRight: "20px" }} />{" "} */}
            <Paused
              style={{ cursor: "pointer", height: "30px", width: "30px" }}
              onClick={(e) => {
                e.preventDefault();
                manageToggle();
              }}
            />
          </div>
        );

      default:
        return <Paused />;
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        zIndex: zIndex.modal,
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        top: "1.5%",
        right: "4%",
        width: "40%",
      }}
    >
      {menu()}
    </div>
  );
};

export interface ConfirmationComponentProps {
  yesFunction: Function;
  noFunction: Function;
}

export const ConfirmationComponent = (props: ConfirmationComponentProps) => {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: zIndex.modal,
        display: "flex",
        flexDirection: "column",
        top: "1.5%",
        left: "4%",
        width: "40%",
      }}
    >
      <p
        style={{
          fontSize: "14px",
          fontWeight: "normal",
          color: "#000000",
          margin: "0 0 15px 0",
        }}
      >
        ¿ Deseas pausar el proceso y continuar más adelante ?
      </p>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          onClick={(e) => {
            e.preventDefault();
            props.yesFunction();
          }}
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#000",
            padding: "6px 10px 6px 20px",
            background: "#FFF",
            border: "none",
            borderRadius: "30px 0 0 30px",
            width: "50px",
            cursor: "pointer",
          }}
        >
          SI
        </div>
        <div
          onClick={(e) => {
            e.preventDefault();
            props.noFunction();
          }}
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#FFF",
            padding: "6px 25px 6px 10px",
            background: "#000",
            border: "none",
            borderRadius: "0 30px 30px 0",
            width: "50px",
            cursor: "pointer",
          }}
        >
          NO
        </div>
      </div>
    </div>
  );
};
