import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { Button2, ButtonIns, ButtonStyle } from "./../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";
import logoIns from "../../assets/logoins.png";

import "./ins_new.scss";
import useQuery from "../../utils/UseQuery";
import DummiesApi from "../../api/dummies_api";

function InsFormIntro() {
  const api = new DummiesApi();
  var [bgColor, setBgColor] = useState(["#008946", "#006b39"]);
  const history = useHistory();
  const query = useQuery();
  const filledFormIdOpt = query.get("filledFormId");
  const regIdOpt = query.get("registrationId");
  const regId = "619b6ea297642d759c4a9c24"; //zamba
  const formId = "621585769ffb931f0be5cfa3"; //formId
  //Creates the form
  async function createForm() {
    var resp = await api.sendForm({}, formId);
    if (!resp) {
      console.log("ERROR, resp is null");
      return;
    }
    console.log("filledFormId = " + resp.id);
    return resp.id;
  }

  async function updateCoreId(filledFormId) {
    if (!regIdOpt) await api.addCoreIdData(regId, filledFormId);
    else await api.addCoreIdData(regIdOpt, filledFormId);
  }

  async function updateForm() {
    var resp = await api.updateForm();
  }

  async function nextPage() {
    var filledFormId = await createForm();
    console.log("filledFormId");
    await updateCoreId(filledFormId);
    var currentFilledForm = await api.getFilledForm(filledFormId);
    console.log(currentFilledForm);
    history.push("/insForm1?filledFormId=" + filledFormId);
  }

  const next = () => {
    history.push("./insForm1" + history.location.search);
  };
  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div>
        <div className="col" style={{ marginTop: "5vh" }}>
          <img src={logoIns}></img>
          <div style={{ marginTop: "10vh" }}>
            <h1 className="txt-label txt-welcomeMessage">Demo Grupo INS</h1>
          </div>
          <div style={{ marginTop: "10vh" }}></div>
          <div style={{ marginTop: "10vh" }}>
            <ButtonStyle
              className="button-custom"
              text="Seguros 0 KMs"
              width={"60vw"}
              height={"50px"}
            />
          </div>

          <div style={{ marginTop: "7vh" }}>
            <ButtonStyle
              className="button-custom"
              text="Seguro Viaje"
              width={"60vw"}
              height={"50px"}
              onClick={() => nextPage()}
            />
          </div>

          <div style={{ marginTop: "7vh" }}>
            <ButtonStyle
              className="button-custom"
              text="Voluntario Automovil"
              width={"60vw"}
              height={"50px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default InsFormIntro;
