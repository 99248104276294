import React from "react";
import "./SliderSecurity2.scss";
import BoxOption from "./BoxOption";
import less from "./../../assets/less.png";
import {useHistory} from 'react-router-dom';
import { Button2, ButtonStyle } from "../../utils/Buttons/Buttons";

function SliderSecurity2(props) {
  let history= useHistory();
  const Next=()=>{
history.push("slider1"+ history.location.search);
  }
  const NextOptions=()=>{
    props.redirectmfa();      }
  return (
    <div>
        <p className='txt-title'>Selecciona la opción de aseguramiento</p>
       <BoxOption
          option="OPCIÓN 03"
          price1="$ 116,58"
          text="Muerte Accidental del AseguradoGastos Médicos y AdicionalesRenta Diaria por HospitalizaciónCancelación y acortamiento de viajeRetraso en el ViajePérdida de EquipajePérdida de PasaporteResponsibilidad Civil x Lesión y/o muerteHonorarios Legales x Acc. TransporteFianzas Legales"
          price2="$ 132.275"
          selectedValue={props.selectedValue} 
          setSelectedValue={props.setSelectedValue}
          value="c"
        />
        <BoxOption
          option="OPCIÓN 04"
          price1="$ 123,52"
          text="Muerte Accidental del AseguradoGastos Médicos y AdicionalesRenta Diaria por HospitalizaciónCancelación y acortamiento de viajeRetraso en el ViajePérdida de EquipajePérdida de PasaporteResponsibilidad Civil x Lesión y/o muerteHonorarios Legales x Acc. TransporteFianzas Legales"
          price2="$ 132.275"
          selectedValue={props.selectedValue} 
          setSelectedValue={props.setSelectedValue}
          value="d"
        />
         <div className="btn-less">
         <img src={less} alt="less" onClick={() =>Next()}/>
          <p>
            ver más<br></br>
            opciones
          </p>
          <div style={{ margin: "2.4em" , paddingBottom: "3em" }}>
        <ButtonStyle
          text="SIGUIENTE"
          onClick={() => NextOptions()}
          width="177px"
          height="5vh"
        />
      </div>
        </div>
    </div>
  );
}
export default SliderSecurity2;
