import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useQuery from "../../utils/UseQuery";
import DummiesApi from "../../api/dummies_api";
import more from "./../../assets/more.png";

import "./ins_new.scss";
import {
  TextFieldStyleDummies,
  TextFieldStyleDummies2,
} from "../../utils/TextFieldStyle/TextFieldStyle";
import { ButtonIns, ButtonStyle } from "../../utils/Buttons/Buttons";

function InsResumen1(props) {
  let history = useHistory();
  var [bgColor, setBgColor] = useState(["#008946", "#006b39"]);
  const api = new DummiesApi();
  const query = useQuery();
  const filledFormId = query.get("filledFormId");

  //Control
  const [currentPage, setCurrentPage] = useState(0);

  function nextPage() {
    if (currentPage === 0) setCurrentPage(1);
  }

  function prevPage() {
    if (currentPage === 1) setCurrentPage(0);
  }

  //Data
  const [date, setDate] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [noPoliza, setNoPoliza] = useState("0221VIA007575100");
  const [consecutivo, setConsecutivo] = useState("7643955-14336138-1015");
  const [sede, setSede] = useState("Sede Virtual");
  const [numIdent, setNumIdent] = useState("");
  const [fullName, setFullName] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");

  //Data 2
  const [address, setAddress] = useState("");
  const [destination, setDestination] = useState("");
  const [paquete, setPaquete] = useState("Seguro para viajeros con asistencia");
  const [frecuencia, setFrecuencia] = useState("Costo ( L,V,I )");
  const [prima, setPrima] = useState("");
  const [vigencia, setVigencia] = useState("");

  function formatDate(date) {
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let day = date.getDate();
    return `${day}/${month}/${year}`;
  }

  async function getData() {
    var filledForm = await api.getFilledForm(filledFormId);
    let data = filledForm.data;
    console.log(data);
    setDate(formatDate(new Date()));
    setInitialDate(data.initialDate);
    setNumIdent(data.document);
    setFullName(data.lastName + " " + data.name);
    setCelular(data.phone);
    setEmail(data.email);

    //set data 2
    setAddress(data.address);
    setDestination(data.destination);
    setPrima(data.prima);
    setVigencia(data.initialDate + " - " + data.endDate);
  }

  useEffect(() => {
    getData();
  }, []);

  async function nextScreen() {
    history.push("/insFormPay" + history.location.search);
  }

  if (currentPage === 0)
    return (
      <div
        style={{
          background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        {/* Main Col */}
        <div className="col">
          {/* titlesRow */}
          <div
            className="row"
            style={{ marginTop: "7vh", marginBottom: "5vh" }}
          >
            <p className="txt-label">Resumen de tu seguro</p>
            <p className="txt-title">Datos del asegurado</p>
          </div>
          {/* fecha */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Fecha"
              value={date}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* No. Poliza */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="No. Poliza"
              value={noPoliza}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Consecutivo */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Consecutivo No."
              value={consecutivo}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Sede */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Sede"
              value={sede}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Identificacion */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Identificación"
              value={numIdent}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* fullName */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Nombre Completo"
              value={fullName}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* email */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Correo Electrónico"
              value={email}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* teléfono */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="No. Celular"
              value={celular}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* dirección */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Tu Dirección"
              value={address}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Destino */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Destino del viaje"
              value={destination}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Paquete */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Paquete"
              value={paquete}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Frecuencia de pago */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Frecuencia de pago"
              value={frecuencia}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Prima */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Prima"
              value={prima}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Vigencia */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Vigencia del seguro"
              value={vigencia}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Button Next */}
          <div
            className="row btn-more"
            style={{ marginBottom: "10vh" }}
            onClick={() => nextPage()}
          >
            <div className="col">
              <ButtonStyle
                text="Aceptar"
                onClick={() => nextScreen()}
                width="177px"
                height="5vh"
              />
            </div>
          </div>

          {/* Button More
          <div className="row btn-more" onClick={() => nextPage()}>
            <div className="col">
              <p className="row-12" style={{ width: "100%" }}>
                ver más<br></br>
                opciones
              </p>
              <img src={more} alt="more" />
            </div>
          </div> */}
        </div>
      </div>
    );
  else
    return (
      <div
        style={{
          background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        {/* Main Col */}
        <div className="col">
          {/* dirección */}
          <div
            className="row"
            style={{ marginTop: "20vh", marginBottom: "5vh" }}
          >
            <TextFieldStyleDummies2
              label="Tu Dirección"
              value={address}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Destino */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Destino del viaje"
              value={destination}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Paquete */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Paquete"
              value={paquete}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Frecuencia de pago */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Frecuencia de pago"
              value={frecuencia}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Prima */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Prima"
              value={prima}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Vigencia */}
          <div className="row" style={{ marginBottom: "5vh" }}>
            <TextFieldStyleDummies2
              label="Vigencia del seguro"
              value={vigencia}
              style={{ width: "70vw" }}
              onChange={(e) => {
                //   setDestination(e.target.value);
                // userChange("destino", e.target.value);
              }}
            />
          </div>

          {/* Button Next */}
          <div className="row btn-more" onClick={() => nextPage()}>
            <div className="col">
              <ButtonStyle
                text="Aceptar"
                onClick={() => nextScreen()}
                width="177px"
                height="5vh"
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default InsResumen1;
