import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import {useHistory} from 'react-router-dom'


import "./IVamosCuentaAhorros.scss";

function IVamosCuentaAhorros() {
  const history = useHistory();
  
  
  const NextOptions1= () => {
    history.push('./JSelecciona'+ history.location.search)
  };
  const NextOptions2= () => {
    history.push('./HQueDeseaHacer'+ history.location.search)
  };
  return (
    <div className="screen1">
      <div className="box_one" style={{marginBottom:'2em', marginTop: "20vh"}}>
        <p className="title2">Vamos a realizar:</p>
        <p className="title2">Apertura de cuenta de ahorros</p>
        <p className="title2">¿Deseas continuar?</p>

      </div>

   
      <div style={{ marginTop: "3em" }}>
        <ButtonStyle
          text="CONTINUAR"
           onClick={() => NextOptions1()}
          width="177px"
          height="5vh"
        />
         </div>
        <div style={{ marginTop: "1em" }}>
       
        <ButtonStyle
          text="VOLVER"
           onClick={() => NextOptions2()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default IVamosCuentaAhorros;
