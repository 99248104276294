import React from "react";
import "./SelectOption.scss";
function SelectOption({ number, text }) {
  return (
    <div className="select-option">
      <div>
        <fieldset>
          <legend>{number}</legend>
          <div className="select-text">
            <span>{text}</span>
          </div>
        </fieldset>
      </div>
    </div>
  );
}

export default SelectOption;
