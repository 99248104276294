import React from "react";
import "./ScreenOptions.scss";
import SelectOption from "./../../utils/SelectOption/SelectOption";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "center",
  },
}));
function ScreenOptions() {
  const classes = useStyles();
  const dataselect = [
    { id: 1, number: "01", text: "MATRICULA REGISTRO" },

    { id: 3, number: "03", text: "TRASLADO MATRÍCULA REGISTRO" },

    { id: 5, number: "05", text: "CAMBIO DE COLOR" },

    { id: 7, number: "07", text: "REGRABAR MOTOR" },

    { id: 9, number: "09", text: "DUPLICADO LICENCIA TRANSITO" },

    { id: 11, number: "11", text: "LEVANTA PRENDA" },

    { id: 13, number: "13", text: "DUPLICADO DE PLACAS" },

    { id: 15, number: "15", text: "CAMBIO DE CARROCERÍA" },
  ];
  const dataselect2 = [
    { id: 2, number: "02", text: "TRASPASO" },

    { id: 4, number: "04", text: "RADICADO MATRÍCULA REGISTRO" },

    { id: 6, number: "06", text: "CAMBIO DE SERVICIO" },

    { id: 8, number: "08", text: "TRANSFORMACIÓN" },

    { id: 10, number: "10", text: "INSCRIPCIÓN PRENDA" },

    { id: 12, number: "12", text: "CANCELACIÓN MATRÍCULA REGISTRO" },

    { id: 14, number: "14", text: "RE-MATRÍCULA" },

    { id: 16, number: "16", text: "OTROS" },
  ];
  return (
    <div className="options-style">
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {dataselect.map((i) => (
              <Link to={"/enter"} style={{ textDecoration: "none" }}>
                <SelectOption number={i.number} text={i.text} />
              </Link>
            ))}
          </Grid>
          <Grid item xs={6}>
            {dataselect2.map((i) => (
              <Link to={"/enter"} style={{ textDecoration: "none" }}>
                <SelectOption number={i.number} text={i.text} />
              </Link>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default ScreenOptions;
