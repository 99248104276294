import React from "react";
import "./LogoLocation.scss";
import img from "./../../assets/logoposition.png";
import img2 from "./../../assets/logoposition2.png";
import img3 from "./../../assets/logoposition3.png";
function LogoLocation() {
  return (
    <div className="logo-location">
      <div className="mini-nav">
        <p>Logo Location</p>
      </div>

      <div className="images">
        <div className="div-img">
          <p>brand logo location entrance app</p>
          <img src={img} alt="img1" />
        </div>
        <div className="div-img">
          <p>brand logo location app</p>
          <img src={img2} alt="img1" />
        </div>
        <div className="div-img">
          <p>client logo location app</p>
          <img src={img3} alt="img1" />
        </div>
      </div>
    </div>
  );
}
export default LogoLocation;
