import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DummiesApi from "../../api/dummies_api";
import { Button, Button3 } from "../../utils/Buttons/Buttons";
import { TextFieldStyle } from "../../utils/TextFieldStyle/TextFieldStyle";
import { TextField1 } from "../InputT/TextField1/TextField1";
import { infoMessage, successMessage } from "../../api/errorHandler";
import useQuery from "../../utils/UseQuery";
import "./InitialForm.scss";
import { GenericField } from "./GenericField";
import { useHistory } from "react-router-dom";

function WelcomeFormScreen(props) {
  let history = useHistory();
  const query = useQuery();
  const id = query.get("id");
  console.log(id);
  const api = new DummiesApi();
  var [data, setData] = useState();
  // var [bgColor, setBgColor] = useState(["#292840", "#566075"]);
  var [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);

  const [step, setStep] = useState(0);

  //Load Data
  useEffect(() => {
    console.log("loading data... for id: " + id);
    if (!id) {
      infoMessage("Su código no es válido!");
      return;
    }
    api.getForm(id).then((res) => {
      if (res) {
        setData(res);
        let newColor = [`#${res.firstColorHex}`, `#${res.secondColorHex}`];
        console.log(newColor);
        setBgColor(newColor);
      }
    });
  }, []);

  //Timer
  useEffect(() => {
    const interval = setInterval(() => {
      setStep((index) => (index < 1 ? index + 1 : index));
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  async function generateData() {
    history.push("/introForm/?id=" + id);
  }

  if (!data) {
    return (
      <div
        style={{
          background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <h1 style={{ color: "white" }}>Cargando...</h1>
      </div>
    );
  }

  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      {step === 0 && (
        <div>
          <div className="col" style={{ marginTop: "40vh" }}>
            <h1 className="txt-label" style={{ fontSize: "20vw" }}>
              ¡Hola!
            </h1>
          </div>
        </div>
      )}
      {step === 1 && (
        <div>
          <div
            className="col"
            style={{
              marginTop: "35vh",
              marginLeft: "10vw",
              marginRight: "10vw",
            }}
          >
            <h1 className="txt-label">
              Por favor selecciona el tipo de producto del que deseas disponer
            </h1>
          </div>
          <div style={{ marginTop: "10vh", marginBottom: "30%" }}>
            <Button
              className="button-custom"
              text={data.processName}
              onClick={() => generateData()}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default WelcomeFormScreen;
