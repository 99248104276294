import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DummiesApi from "../../api/dummies_api";
import { Button } from "../../utils/Buttons/Buttons";
import { TextFieldStyle } from "../../utils/TextFieldStyle/TextFieldStyle";
import { TextField1 } from "../InputT/TextField1/TextField1";
import { infoMessage, successMessage } from "../../api/errorHandler";
import useQuery from "../../utils/UseQuery";
import "./InitialForm.scss";

function AuthCallback(props) {
  const query = useQuery();
  const id = query.get("filledFormId");
  const authId = query.get("authId");
  console.log(id);
  const regId = query.get("registrationId");
  const api = new DummiesApi();
  var [data, setData] = useState();
  var [txtFields, setTxtFields] = useState({});
  var [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  // var [bgColor, setBgColor] = useState(["#292840", "#566075"]);

  useEffect(() => {
    api.getFormByFilledFormId(id).then((res) => {
      if (res) {
        let newColor = [`#${res.firstColorHex}`, `#${res.secondColorHex}`];
        console.log(newColor);
        setBgColor(newColor);
      }
    });
    loadData();
  }, []);

  async function loadData() {
    var authProcess = await api.getAuthProcess(authId, id);
    if (!authProcess) {
      infoMessage("Error obteniendo autenticación");
      return;
    }
    console.log(authProcess);
    if (authProcess.score < 80) {
      infoMessage("Su autenticación no fue exitosa");
      return;
    }
    successMessage(
      "Tu proceso ha sido exitoso, en tu correo encontrarás información de tu solicitud de crédito"
    ).then((e) => {
      api.sendFinalEmail(id)
      window.location.href = "https://anteia.co";
    });
  }

  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        backgroundAttachment: "fixed",
        position: "fixed",
        height: "100%",
        width: "100%",
      }}
    >
      <h1></h1>
    </div>
  );
}

export default AuthCallback;
