import "./infoUsuarioDummy.scss";

import { ReactComponent as LEFT } from "../../../assets/Polygon 7.svg";
import { ReactComponent as RIGHT } from "../../../assets/Polygon 8.svg";
import IMGCEL from "../../../assets/IMGCEL.png";
import BRILLOID from "../../../assets/DESTELLO-BRILLO-CARA 1.png";
import anteiaTik from "../../../assets/anteiaTik.mp4";
import { useState } from "react";

export const InfoUsuarioDummy = () => {
  const [esConocerteMas, setEsConocerteMas] = useState(true);
  const [esVerificacionIdentidad, setEsVerificacionIdentidad] = useState(false);
  const [esQueremosMejorNitidez, setEsQueremosMejorNitidez] = useState(false);
  const [esVideo, setEsVideo] = useState(false);
  const [esError, setEsError] = useState(false);
  const detectoUnBrillo = "Detecto un brillo en tu documento";
  var i = 0;
  const consejo =
    "Para mejorar la captura de tu documento... te sugerimos colocarlo sobre una superficie plana e inclinarlo un poco de esta manera evitaras reflejos sobre el mismo.";
  var j = 0;

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function conocerteMas() {
    setEsConocerteMas(true);
    setEsVerificacionIdentidad(false);
  }

  function verificacionIdentidad() {
    setEsConocerteMas(false);
    setEsVerificacionIdentidad(true);
    setEsQueremosMejorNitidez(false);
  }

  function queremosMejorNitidez() {
    setEsVerificacionIdentidad(false);
    setEsQueremosMejorNitidez(true);
    setEsError(false);
  }

  async function error() {
    setEsQueremosMejorNitidez(false);
    setEsError(true);
    setEsVideo(false);
    await delay(0);
    typeWriter();
    await delay(3000);
    typeWriterConcejo();
    await delay(8000);
  }

  function video() {
    setEsVideo(true);
    setEsError(false);
  }

  function routeFace() {
    window.location.href = "/faceUsuarioDummy";
  }

  function typeWriter() {
    const textAte = document.getElementById("textDetectoBrillo");
    const barraCont = document.getElementById("barraConcejo");
    const imgCed = document.getElementById("imgCedula");
    if (barraCont) {
      barraCont.style.border = "none";
    }
    if (imgCed) {
      imgCed.style.width = "0%";
    }
    if (i <= detectoUnBrillo.length && textAte) {
      textAte.innerHTML += detectoUnBrillo.charAt(i);
      i++;
      setTimeout(typeWriter, 30);
    }
  }

  function typeWriterConcejo() {
    const textCont = document.getElementById("textConcejo");
    const barraCont = document.getElementById("barraConcejo");
    const imgCed = document.getElementById("imgCedula");
    if (imgCed) {
      imgCed.style.width = "100%";
    }
    if (barraCont) {
      barraCont.style.borderLeft = "4px solid #00BCE2";
      barraCont.style.height = "1.5rem";
      barraCont.style.marginRight = "1rem";
    }
    if (j <= consejo.length && textCont) {
      textCont.innerHTML += consejo.charAt(j);
      j++;
      setTimeout(typeWriterConcejo, 30);
    }
  }

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      {esConocerteMas && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(114.84% 88.52% at 111.93% 89.43%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              position: "absolute",
              top: "1rem",
              right: "1rem",
            }}
          ></button>
          <div style={{ display: "flex" }}>
            <div
              style={{
                height: "100vh",
                width: "15vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
            <div
              style={{
                height: "100vh",
                width: "70vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                margin: "auto",
                textAlign: "start",
              }}
            >
              <h2 style={{ color: "white", marginRight: "5rem" }}>
                Es momento de conocerte un poco más
              </h2>
              <h4 style={{ color: "white" }}>Ingresa los siguientes datos:</h4>
              <div>
                <input id="Natural" type="radio" />
                <label style={{ color: "white" }} htmlFor="Natural">
                  soy persona NATURAL
                </label>
              </div>
              <div>
                <input id="Juridica" type="radio" />
                <label style={{ color: "white" }} htmlFor="Juridica">
                  soy persona JURÍDICA
                </label>
              </div>
              <input
                className="entrada"
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  marginTop: "2rem",
                  borderColor: "white",
                  borderRadius: "10px",
                  height: "2rem",
                  width: "80%",
                }}
                placeholder="No. CELULAR"
                type="number"
              />
              <input
                className="entrada"
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  marginTop: "1rem",
                  borderColor: "white",
                  borderRadius: "10px",
                  height: "2rem",
                  width: "80%",
                }}
                placeholder="CORREO"
                type="text"
              />
            </div>
            <div
              style={{
                height: "100vh",
                width: "15vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={verificacionIdentidad}
              >
                <RIGHT />
              </button>
            </div>
          </div>
        </div>
      )}
      {esVerificacionIdentidad && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(114.42% 100.86% at 104.42% -0.86%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              position: "absolute",
              top: "1rem",
              right: "1rem",
            }}
          ></button>
          <div style={{ display: "flex" }}>
            <div
              style={{
                height: "100vh",
                width: "15vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={conocerteMas}
              >
                <LEFT />
              </button>
            </div>
            <div
              style={{
                height: "100vh",
                width: "70vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                margin: "auto",
                textAlign: "start",
              }}
            >
              <h2 style={{ color: "white", textAlign: "center" }}>
                Verificación de Identidad
              </h2>
              <h5
                style={{ color: "white", fontWeight: "lighter", margin: "0" }}
              >
                Ten a la mano
              </h5>
              <h5 style={{ color: "white", margin: "0" }}>
                tu docummento de identidad
              </h5>
              <h5
                style={{
                  color: "white",
                  fontWeight: "lighter",
                  margin: "2rem",
                  textAlign: "center",
                }}
              >
                Ubícate en un ambiente con buena LUZ
              </h5>
              <h5
                style={{ color: "white", fontWeight: "lighter", margin: "0" }}
              >
                pero no tanta...
              </h5>
              <h5 style={{ color: "white", margin: "0 0 2rem 0" }}>
                ¡Queremos verte a ti y tu documento muy bien!
              </h5>
              <h5
                style={{ color: "white", fontWeight: "lighter", margin: "0" }}
              >
                No olvides
              </h5>
              <h5 style={{ color: "white", margin: "0" }}>
                dar permisos a la cámara de tu celular si te lo solicita
              </h5>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "2rem",
                }}
              >
                <input style={{ marginRight: "0.5rem" }} type="radio" checked />
                <input type="radio" />
              </div>
            </div>
            <div
              style={{
                height: "100vh",
                width: "15vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={queremosMejorNitidez}
              >
                <RIGHT />
              </button>
            </div>
          </div>
        </div>
      )}
      {esQueremosMejorNitidez && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            background: "radial-gradient(#00D8FF, #002039)",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              position: "absolute",
              top: "1rem",
              right: "1rem",
            }}
          ></button>
          <div style={{ display: "flex" }}>
            <div
              style={{
                height: "100vh",
                width: "15vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={verificacionIdentidad}
              >
                <LEFT />
              </button>
            </div>
            <div
              style={{
                height: "100vh",
                width: "70vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                margin: "auto",
                textAlign: "start",
              }}
            >
              <h5
                style={{ color: "white", fontWeight: "lighter", margin: "0" }}
              >
                Queremos obtener la mejor nitidez que tu cámara proporcione al
                tomar tu documento de identidad y tu rostro
              </h5>
              <img style={{ width: "100%" }} src={IMGCEL} alt="imgCel" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}
              >
                <h5
                  style={{
                    color: "white",
                    margin: "0",
                    textAlign: "end",
                    fontWeight: "lighter",
                  }}
                >
                  Un consejo
                </h5>
                <h5
                  style={{
                    color: "white",
                    margin: "0",
                    textAlign: "end",
                    fontWeight: "lighter",
                  }}
                >
                  Inclina ligeramente tu documento cuando realices la captura
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "2rem",
                }}
              >
                <input style={{ marginRight: "0.5rem" }} type="radio" />
                <input type="radio" checked />
              </div>
            </div>
            <div
              style={{
                height: "100vh",
                width: "15vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={error}
              >
                <RIGHT />
              </button>
            </div>
          </div>
        </div>
      )}
      {esError && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            background: "radial-gradient(#00D8FF, #002039)",
          }}
        >
          <div
            style={{
              height: "95vh",
              width: "95vw",
              display: "flex",
              flexDirection: "column",
              margin: "1rem auto",
              backgroundColor: "white",
              borderRadius: "20px",
            }}
          >
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                position: "absolute",
                top: "1rem",
                right: "1rem",
              }}
            ></button>
            <div
              style={{
                height: "100vh",
                width: "70vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                margin: "auto",
                textAlign: "start",
              }}
            >
              <h1
                style={{
                  fontSize: "3rem",
                  color: "#00BCE2",
                  fontWeight: "bolder",
                  margin: "auto 0 0 0",
                }}
              >
                UPS!
              </h1>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    borderLeft: "4px solid #00BCE2",
                    height: "1.5rem",
                    marginRight: "1rem",
                  }}
                ></div>
                <p
                  id="textDetectoBrillo"
                  style={{ fontWeight: "bolder", margin: "0 0 1rem 0" }}
                ></p>
              </div>
              <img
                id="imgCedula"
                style={{ width: "100%", marginBottom: "1rem" }}
                src={BRILLOID}
                alt="cedulaBillo"
              />
              <div style={{ display: "flex" }}>
                <div
                  id="barraConcejo"
                  style={{
                    borderLeft: "4px solid #00BCE2",
                    height: "1.5rem",
                    marginRight: "1rem",
                  }}
                ></div>
                <p
                  id="textConcejo"
                  style={{ fontWeight: "bolder", margin: "0" }}
                ></p>
              </div>
              <button
                onClick={video}
                style={{
                  width: "80%",
                  height: "2.5rem",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "10px",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                VOLVER A INTENTARLO
              </button>
            </div>
          </div>
        </div>
      )}
      {esVideo && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            background: "radial-gradient(#00D8FF, #002039)",
          }}
        >
          <video
            style={{ width: "100%", opacity: "50%" }}
            autoplay="autoplay"
            src={anteiaTik}
            type="video/mp4"
          />
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              right: "1rem",
              top: "1rem",
              position: "absolute",
            }}
          ></button>
          <button
            style={{
              backgroundColor: "black",
              border: "none",
              bottom: "5rem",
              left: "20%",
              position: "absolute",
              height: "3rem",
              width: "60%",
              color: "white",
              borderRadius: "30px",
            }}
            onClick={routeFace}
          >
            ENTENDIDO
          </button>
        </div>
      )}
    </div>
  );
};
