import "./coreIdDummy.scss";
import { useEffect, useState } from "react";
import { ReactComponent as AnteiaR } from "../../assets/anteiaR.svg";
import { ReactComponent as ID } from "../../assets/ID.svg";
import { ReactComponent as APERTURE } from "../../assets/APERTURE.svg";
import { ReactComponent as MICROPHONE } from "../../assets/MICROPHONE.svg";
import { ReactComponent as SMS } from "../../assets/SMS.svg";
import { ReactComponent as FINGERPRINT } from "../../assets/FINGERPRINT.svg";
import { ReactComponent as UBICACION } from "../../assets/UBICACION.svg";
import { ReactComponent as OTROS } from "../../assets/OTROS.svg";

import { transform } from "typescript";

export const CoreIdDummy = () => {
  const [cargo, setCargo] = useState(false);
  const [continuar, setContinuar] = useState(false);
  const [aceptarTerm, setAceptarTerm] = useState(false);
  const textAtenea =
    "Seré tu asistente personal en esta experiencia, no dudes en llámarme en cualquier momento, simplemente tocando mi rostro que aparecerá en tu pantalla en la parte superior derecha... podré ayudarte con tus consultas e inquietudes";
  var i = 0;
  const textContinuemos = "Continuemos...";
  var j = 0;

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function cargarInfo() {
    await delay(3000);
    setCargo(true);
  }

  function siguiente() {
    setContinuar(true);
  }

  async function aceptar() {
    setAceptarTerm(true);
    await delay(0);
    //const aten = document.getElementById('atenea');
    //if(aten){
    //    aten.classList.toggle("flip");
    //}
    typeWriter();
    await delay(8000);
    typeWriterContinuemos();
    await delay(3000);
    window.location.href = "/infoUsuarioDummy";
  }

  function rechazar() {
    setContinuar(false);
  }

  function typeWriter() {
    const textAte = document.getElementById("textAtenea");
    const barraCont = document.getElementById("barraContinuemos");
    if (barraCont) {
      barraCont.style.border = "none";
    }
    if (i <= textAtenea.length && textAte) {
      textAte.innerHTML += textAtenea.charAt(i);
      i++;
      setTimeout(typeWriter, 30);
    }
  }

  function typeWriterContinuemos() {
    const textCont = document.getElementById("textContinuemos");
    const barraCont = document.getElementById("barraContinuemos");
    if (barraCont) {
      barraCont.style.borderLeft = "4px solid #00BCE2";
      barraCont.style.height = "1.5rem";
      barraCont.style.marginRight = "1rem";
    }
    if (j <= textContinuemos.length && textCont) {
      textCont.innerHTML += textContinuemos.charAt(j);
      j++;
      setTimeout(typeWriterContinuemos, 30);
    }
  }

  useEffect(() => {
    cargarInfo();
  }, []);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      {!cargo && !continuar && !aceptarTerm && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(181.87deg, #00D8FF -1.01%, #011A2E 100.65%)",
          }}
        >
          <h5 style={{ color: "white", margin: "0 10rem 1rem 0" }}>
            Bienvenidos a
          </h5>
          <AnteiaR style={{ scale: "3" }} />
        </div>
      )}
      {cargo && !continuar && !aceptarTerm && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(125.31% 87.93% at 111.93% 89.43%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <div
            style={{
              height: "100vh",
              width: "60vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "auto",
            }}
          >
            <h5 style={{ color: "white", margin: "0 auto 2rem 0" }}>
              Antes de Comenzar
            </h5>
            <AnteiaR style={{ scale: "3", marginLeft: "2rem" }} />
            <h5
              style={{
                color: "white",
                textAlign: "start",
                marginLeft: "1.5rem",
              }}
            >
              necesitarás acceder, recolectar y almacenar datos de tu equipo
            </h5>
            <div
              style={{ display: "flex", width: "100%", margin: "1rem 0 0 0" }}
            >
              <div style={{ width: "2rem" }}>
                <ID />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                }}
              >
                <h5 style={{ margin: "0", color: "white", marginLeft: "1rem" }}>
                  Identidad
                </h5>
                <h5
                  style={{
                    margin: "0",
                    color: "white",
                    marginLeft: "1rem",
                    fontWeight: "lighter",
                  }}
                >
                  Consultar la identidad y estado del dispositivo
                </h5>
              </div>
            </div>
            <div
              style={{ display: "flex", width: "100%", margin: "1rem 0 0 0" }}
            >
              <div style={{ width: "2rem" }}>
                <APERTURE />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                }}
              >
                <h5 style={{ margin: "0", color: "white", marginLeft: "1rem" }}>
                  Cámara de vídeo
                </h5>
                <h5
                  style={{
                    margin: "0",
                    color: "white",
                    marginLeft: "1rem",
                    fontWeight: "lighter",
                  }}
                >
                  Realizar fotografías y videos
                </h5>
              </div>
            </div>
            <div
              style={{ display: "flex", width: "100%", margin: "1rem 0 0 0" }}
            >
              <div style={{ width: "2rem" }}>
                <MICROPHONE />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                }}
              >
                <h5 style={{ margin: "0", color: "white", marginLeft: "1rem" }}>
                  Micrófono
                </h5>
                <h5
                  style={{
                    margin: "0",
                    color: "white",
                    marginLeft: "1rem",
                    fontWeight: "lighter",
                  }}
                >
                  Grabar sonido
                </h5>
              </div>
            </div>
            <div
              style={{ display: "flex", width: "100%", margin: "1rem 0 0 0" }}
            >
              <div style={{ width: "2rem" }}>
                <SMS />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                }}
              >
                <h5 style={{ margin: "0", color: "white", marginLeft: "1rem" }}>
                  SMS
                </h5>
                <ul
                  style={{
                    color: "white",
                    padding: "0 0 0 2rem",
                    fontWeight: "lighter",
                    fontSize: "0.7rem",
                  }}
                >
                  <li>Recibir mensajes de texto (sms)</li>
                  <li>Enviar mensajes (sms)</li>
                  <li>Leer mensajes (sms)</li>
                </ul>
              </div>
            </div>
            <div
              style={{ display: "flex", width: "100%", margin: "1rem 0 0 0" }}
            >
              <div style={{ width: "2rem" }}>
                <FINGERPRINT />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                }}
              >
                <h5
                  style={{
                    margin: "0",
                    color: "white",
                    marginLeft: "1rem",
                    fontWeight: "lighter",
                  }}
                >
                  Reconocimiento facial y datos biométricos
                </h5>
              </div>
            </div>
            <button
              onClick={siguiente}
              style={{
                marginTop: "2rem",
                color: "white",
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
                border: "0",
                width: "80%",
                height: "3rem",
                borderRadius: "50px",
              }}
            >
              SIGUIENTE
            </button>
          </div>
        </div>
      )}
      {cargo && continuar && !aceptarTerm && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(115.84% 103.11% at 107.56% 1.61%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <div
            style={{
              height: "100vh",
              width: "60vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "auto",
            }}
          >
            <div
              style={{ display: "flex", width: "100%", margin: "1rem 0 0 0" }}
            >
              <div style={{ width: "2rem" }}>
                <UBICACION />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                }}
              >
                <h5 style={{ margin: "0", color: "white", marginLeft: "1rem" }}>
                  Ubicación
                </h5>
                <ul
                  style={{
                    color: "white",
                    padding: "0 0 0 2rem",
                    fontWeight: "lighter",
                    fontSize: "0.7rem",
                  }}
                >
                  <li>Acceder a tu ubicación aproximada (basada en la red)</li>
                  <li>Acceder a tu ubicación precisa (basada en red GPS)</li>
                </ul>
              </div>
            </div>
            <div
              style={{ display: "flex", width: "100%", margin: "1rem 0 0 0" }}
            >
              <div style={{ width: "2rem" }}>
                <OTROS />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                }}
              >
                <h5 style={{ margin: "0", color: "white", marginLeft: "1rem" }}>
                  Otros
                </h5>
                <ul
                  style={{
                    color: "white",
                    padding: "0 0 0 2rem",
                    fontWeight: "lighter",
                    fontSize: "0.7rem",
                  }}
                >
                  <li>Ver conexiones de red</li>
                  <li>Recibir datos de internet</li>
                  <li>Permitir acceso completo a la red</li>
                  <li>Contectarse y desconectarse de la red WI-FI</li>
                  <li>
                    Evitar que el dispositivo entre en estado de inactividad
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", textAlign: "start" }}>
              <div style={{ width: "2rem" }}>
                <input
                  style={{
                    backgroundColor: "black",
                    width: "1.5rem",
                    height: "1.5rem",
                  }}
                  type="checkbox"
                />
              </div>
              <h5
                style={{
                  margin: "0",
                  color: "white",
                  marginLeft: "1rem",
                  fontWeight: "lighter",
                  fontSize: "0.7rem",
                }}
              >
                Marca esta opción para dar el consentimiento libre, expreso que
                has sido informado de los términos y condiciones, política de
                privacidad y tratamiento de datos sensibles.
              </h5>
            </div>
            <button
              onClick={aceptar}
              style={{
                marginTop: "2rem",
                color: "white",
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
                border: "0",
                width: "80%",
                height: "3rem",
                borderRadius: "50px",
              }}
            >
              ACEPTAR
            </button>
            <button
              onClick={rechazar}
              style={{
                marginTop: "1rem",
                color: "white",
                border: "1px solid rgba(255, 255, 255, 0.2)",
                width: "80%",
                height: "3rem",
                borderRadius: "50px",
                backgroundColor: "transparent",
              }}
            >
              RECHAZAR
            </button>
          </div>
        </div>
      )}
      {cargo && continuar && aceptarTerm && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(209.61% 195.98% at 50% -10.68%, #00D8FF 0%, #002039 100%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            id="atenea"
            className="atenea"
            style={{
              height: "95vh",
              width: "95vw",
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              backgroundColor: "white",
              borderRadius: "20px",
            }}
          >
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                position: "absolute",
                top: "1rem",
                right: "1rem",
              }}
            ></button>
            <div
              style={{
                height: "100vh",
                width: "70vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                margin: "auto",
                textAlign: "start",
              }}
            >
              <div style={{ display: "flex" }}>
                <h1
                  style={{
                    fontSize: "3rem",
                    color: "#00BCE2",
                    fontWeight: "bolder",
                    margin: "auto 0 0 0",
                  }}
                >
                  Hola!
                </h1>
                <h3
                  style={{
                    fontWeight: "lighter",
                    margin: "auto 0 0.5rem 0.5rem",
                  }}
                >
                  Soy
                </h3>
                <h3 style={{ margin: "auto 0 0.5rem 0.5rem" }}>atenea</h3>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    borderLeft: "4px solid #00BCE2",
                    height: "1.5rem",
                    marginRight: "1rem",
                  }}
                ></div>
                <p
                  id="textAtenea"
                  style={{ fontWeight: "bolder", margin: "0 0 1rem 0" }}
                ></p>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  id="barraContinuemos"
                  style={{
                    borderLeft: "4px solid #00BCE2",
                    height: "1.5rem",
                    marginRight: "1rem",
                  }}
                ></div>
                <p
                  id="textContinuemos"
                  style={{ fontWeight: "bolder", margin: "0" }}
                ></p>
              </div>
              <input
                className="placeholder"
                type="text"
                placeholder="Escribe aquí tu consulta"
                style={{
                  backgroundColor: "#00BCE2",
                  marginTop: "3rem",
                  width: "100%",
                  borderRadius: "20px",
                  color: "white",
                  padding: "10px",
                }}
              ></input>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
