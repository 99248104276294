import { useEffect, useState } from "react";
import StyleProperties from "../../dtos/StyleProperties";

export interface SliderProps {
  min: number;
  max: number;
  step: number;
  currentValue: string;
  onChange: (value: string) => void;
  align?: "CENTER" | "LEFT" | "RIGHT";
  style?: StyleProperties;
  darkMode?: boolean;
  disabled?: boolean;
  currency?: string;
  country?: string;
}

export const SliderButton = ({
  min,
  max,
  step,
  currentValue,
  onChange,
  align,
  style,
  darkMode,
  disabled,
  currency,
  country,
}: SliderProps) => {
  const [value, setValue] = useState(max);

  useEffect(() => {
    if (currentValue) {
      setValue(parseInt(currentValue));
    }
  }, [currentValue]);

  const handleSliderChange = (event: any) => {
    let newValue = event.target.value;
    let numericValue = parseInt(newValue);
    setValue(numericValue);
    onChange(newValue);
  };
  const getDefaultMargin = () => {
    if (style && (style.margin || style.marginTop || style.marginBottom)) {
      return {
        margin: style.margin,
        marginTop: style.marginTop,
        marginBottom: style.marginBottom,
      };
    } else {
      return { margin: "15px 0" };
    }
  };

  const formatCurrency = (val: number) => {
    if (currency) {
      // get numeric value deleting symbols, commas, dots, etc

      if (val === 0) return "";

      const formatted = new Intl.NumberFormat(country ? country : "es-CO", {
        style: "currency",
        currency: currency ? currency : "COP",
        currencyDisplay: "narrowSymbol",
        maximumFractionDigits: 0,
      }).format(val);

      return formatted;
    } else {
      return value;
    }
  };

  const getAlign = () => {
    if (align === "RIGHT") {
      return "justify-end items-end";
    } else if (align === "LEFT") {
      return "justify-start items-start";
    } else {
      return "justify-center items-center ";
    }
  };

  const getInputStyles = () => {
    if (style && (style.margin || style.marginTop || style.marginBottom)) {
      return {
        margin: style.margin,
        marginTop: style.marginTop,
        marginBottom: style.marginBottom,
        "--trackColor":
          style && style.unselectedColor
            ? style.unselectedColor
            : "rgb(96, 96, 96)",
        "--thumbColor":
          style && style.selectedColor ? style.selectedColor : "white",
      };
    } else {
      return {
        margin: "15px 0",
        "--trackColor":
          style && style.unselectedColor
            ? style.unselectedColor
            : "rgb(96, 96, 96)",
        "--thumbColor":
          style && style.selectedColor ? style.selectedColor : "white",
      };
    }
  };

  return (
    <div className={`w-full flex-col ${getAlign()}`} style={getDefaultMargin()}>
      <h6 className="text-xl font-semibold" style={style}>
        {formatCurrency(value)}
      </h6>
      <input
        style={getInputStyles()}
        disabled={disabled}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleSliderChange}
        className="slider-thumb-appearance"
      />
    </div>
  );
};
