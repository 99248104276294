import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import {useHistory} from 'react-router-dom'


import "./GHola.scss";

function GHola() {
  const history = useHistory();
  
  const NextOptions= () => {
    history.push('./hQueDeseaHacer'+ history.location.search)
  };
  return (
    <div className="screen1">
      <div className="box_one" style={{marginBottom:'2em', marginTop: "20vh"}}>
        <p className="title1">¡Hola!</p>
        <p className="title2">Bienvenido a tu agencia Virtual</p>
      </div>

   
      <div style={{ marginTop: "3em" }}>
        <ButtonStyle
          text="SIGUIENTE"
           onClick={() => NextOptions()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default GHola;
