import zIndex from "@mui/material/styles/zIndex";

export interface PopUpElementProps {
  content: JSX.Element;
}

export const PopUpElement = (props: PopUpElementProps) => {
  return (
    <div
      style={{
        background: "#FFFFFF",
        border: "none",
        borderRadius: "30px 30px 30px 30px",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        zIndex: zIndex.modal,
        marginLeft: "2%",
        marginRight: "2%",
        left: "0",
        right: "0",
        bottom: "0",
        overflow: "auto",
        padding: "15px 40px 15px 40px",
      }}
    >
      {props.content}
    </div>
  );
};
