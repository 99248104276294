import React from "react";
import "./VerifyData.scss";
import vector from "./../../assets/Vector-5.png";
import car from "./../../assets/siluetacolor.png";
import placa from "./../../assets/placa.png";
import { Button } from "./../../utils/Buttons/Buttons";
import {useHistory} from 'react-router-dom'
import { commonError, fatalError, infoMessage } from "./../../api/errorHandler";

function VerifyData(props) {
    let history = useHistory();
    const NextPay=()=>{
        //history.push('./pay'+ history.location.search);
        props.SendPago();
        infoMessage("Revisa tu correo electronico, para continuar con el proceso.");
    }
  return (
    <div className="verify-style">
      <p>
        La información<br></br>
        ha sido VERIFICADA
      </p>
      <div className="div-img">
        <img src={car} alt="img" width="100%" />
      </div>
      <div className="div-img-icon">
        <img src={vector} alt="img"  />
      </div>
      <div className="div-img">
        <img src={placa} alt="img"  width="100%" />
      </div>
      <div style={{marginTop:'2.2em'}}> 
        <Button
          text="CONTINUAR"
          onClick={() => NextPay()}
          width="177px"
          height="5vh"
        />
        <br/>
        <br/>
      </div>
    </div>
  );
}
export default VerifyData;
