import { configureStore } from "@reduxjs/toolkit";
import formReducer from "../formComponent/formComponentSlicer";
import asesorReducer from "../../asesor/asesorSlice";

//test
export const store = configureStore({
  reducer: {
    form: formReducer,
    asesor: asesorReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
