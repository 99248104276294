import React from "react";
import "./Payment.scss";
import { sha256 } from 'js-sha256';
import img from './../../assets/logo2.png';

function Payment() {

  const calcularesig=() =>{
    var a ="4Vj8eK4rloUd272L48hsrarnUA";
    var b=document.getElementsByName("merchantId").value;
    var c=document.getElementsByName("referenceCode").value;
    var d=document.getElementsByName("amount").value;
    var e="COP";
    return sha256(a+"~"+b+"~"+c+"~"+d+"~"+e);
  }
  
  const getNi=()=>{

    if(getParameterByName('ni')==null){
      return "";
    }
      return getParameterByName('ni').toString();
  }
  const getEmail=()=>{
    if(getParameterByName('e')==null){
      return "test@test.com";
    }
    return getParameterByName('e').toString();
}
  const getParameterByName=(name, url = window.location.href) =>{
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
const getUrlRedi=()=>{

  return "https://pay.anteia.co/?code=testingPreloaded&e="+getEmail().toString()+"&ni="+getNi().toString();
}

    
  return (
    <div className="pay-style">
      <p>
        Es momento de proceder <br></br>
        con el pago de tu trámite
      </p>
 
<a href={getUrlRedi()}>
  <button >
  <img src={img} alt='pay' width="300px" ></img>
  </button>
</a>
  
      <p>
        Seleccionar otro<br></br>
        medio de pago
      </p>
    </div>
  );
}
export default Payment;
