import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    // display: "grid",
    justifyContent: "center",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: "10px",
      color: "white",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      color: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-outlined": {
      color: "white",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",

      fontSize: "16px",
      fontWeight: "800",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
      padding: "10.5px 4px !important",
    },
  },
  rootn: {
    display: "grid",
    justifyContent: "center",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: "10px",
      color: "white",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      color: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-outlined": {
      color: "white",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",

      fontSize: "16px",
      fontWeight: "800",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
      padding: "10.5px 4px !important",
    },
  },
  root2: {
    width: "100%",
  },
  root2n: {
    width: "80vw",
  },
  // ------------------style_textfield2--------------------------
  root1: {
    display: "grid",
    justifyContent: "center",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: "10px",
      color: "white",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      color: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-outlined": {
   
      color: "white",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",
      // paddingRight: "7%",
      fontSize: "15px",
      fontWeight: "800",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
      padding: "10.5px 4px !important",
    },
  },
  root3: {
    width: "140%",
    
  },
  // ------------------style_textfield3--------------------------
  root4: {
    display: "grid",
    justifyContent: "center",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: "10px",
      color: "white",
      fontSize: "25px",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      color: "white",
      
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
      fontWeight: "900",
      fontSize: "25px",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
      
    },
    "& .MuiInputLabel-outlined": {
      fontWeight: "800",
      color: "white",
      fontSize: "20px",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",
      // paddingRight: "7%",
      fontSize: "23px",
      fontWeight: "800",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
      fontSize: "22px",
      padding: "10.5px 4px !important",
    },
  },
  root5: {
    width: "120%",
    
  },
});

const useStylesLight = makeStyles({
  root: {
    // display: "grid",
    justifyContent: "center",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      borderRadius: "10px",
      color: "black",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      color: "black",
    },
    "& .MuiOutlinedInput-input": {
      color: "black",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "black",
    },
    "& .MuiInputLabel-outlined": {
      color: "black",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "black",

      fontSize: "16px",
      fontWeight: "800",
    },
    "& .MuiOutlinedInput-input": {
      color: "black",
      padding: "10.5px 4px !important",
    },
  },
  rootn: {
    display: "grid",
    justifyContent: "center",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      borderRadius: "10px",
      color: "black",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      color: "black",
    },
    "& .MuiOutlinedInput-input": {
      color: "black",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "black",
    },
    "& .MuiInputLabel-outlined": {
      color: "black",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "black",

      fontSize: "16px",
      fontWeight: "800",
    },
    "& .MuiOutlinedInput-input": {
      color: "black",
      padding: "10.5px 4px !important",
    },
  },
  root2: {
    width: "100%",
  },
  root2n: {
    width: "80vw",
  },
  // ------------------style_textfield2--------------------------
  root1: {
    display: "grid",
    justifyContent: "center",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      borderRadius: "10px",
      color: "black",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      color: "black",
    },
    "& .MuiOutlinedInput-input": {
      color: "black",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "black",
    },
    "& .MuiInputLabel-outlined": {
   
      color: "black",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "black",
      // paddingRight: "7%",
      fontSize: "15px",
      fontWeight: "800",
    },
    "& .MuiOutlinedInput-input": {
      color: "black",
      padding: "10.5px 4px !important",
    },
  },
  root3: {
    width: "140%",
    
  },
  // ------------------style_textfield3--------------------------
  root4: {
    display: "grid",
    justifyContent: "center",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      borderRadius: "10px",
      color: "black",
      fontSize: "25px",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      color: "black",
      
    },
    "& .MuiOutlinedInput-input": {
      color: "black",
      fontWeight: "900",
      fontSize: "25px",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "black",
      
    },
    "& .MuiInputLabel-outlined": {
      fontWeight: "800",
      color: "black",
      fontSize: "20px",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "black",
      // paddingRight: "7%",
      fontSize: "23px",
      fontWeight: "800",
    },
    "& .MuiOutlinedInput-input": {
      color: "black",
      fontSize: "22px",
      padding: "10.5px 4px !important",
    },
  },
  root5: {
    width: "120%",
    
  },
});
export const TextFieldStyle = ({ label, onChange, value, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TextField
        label={label}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        className={classes.root2}
        value={value}
        onChange={onChange}
        type={type}
      />
    </div>
  );
};
export const TextFieldStyleSS = ({ label, onChange, value, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.rootn}>
      <TextField
        label={label}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        className={classes.root2n}
        value={value}
        onChange={onChange}
        type={type}
      />
    </div>
  );
};
export const TextFieldStyleSSelect = ({data}) => {
  const classes = useStyles();
  return (
    <div className={classes.rootn}>
     {data}
    </div>
  );
};
export const TextFieldStyle2 = ({ label, onChange, value, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.root1}>
      <TextField
        label={label}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        className={classes.root3}
        value={value}
        onChange={onChange}
        type={type}
      />
    </div>
  );
};
export const TextFieldStyle3 = ({ label, onChange, value, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.root4}>
      <TextField
        label={label}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        className={classes.root5}
        value={value}
        onChange={onChange}
        type={type}
      />
    </div>
  );
};

export const TextFieldStyleDummies = ({
  label,
  onChange,
  value,
  type,
  disabled = false,
  defaultValue,
  InputProps,
  onClick,
  innerRef,
  darkMode = true,
}) => {
  const classes = useStyles();
  const classesLight = useStylesLight();
  return (
    <div className={darkMode?classes.root:classesLight.root}>
      <TextField
        label={label}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        className={classes.root2}
        value={value}
        onChange={onChange}
        type={type}
        disabled={disabled}
        defaultValue={defaultValue}
        InputProps={InputProps}
        onClick={onClick}
        innerRef={innerRef}
      />
    </div>
  );
};

export const TextFieldStyleDummiesAutocomplete = ({
  onChange,
  value,
  disabled = false,
  defaultValue,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TextField
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        className={classes.root2}
        value={value}
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const TextFieldStyleDummies2 = ({
  label,
  onChange,
  value,
  type,
  disabled = false,
  defaultValue,
  InputProps,
  style,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TextField
        label={label}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        className={classes.root2}
        value={value}
        onChange={onChange}
        type={type}
        disabled={disabled}
        defaultValue={defaultValue}
        InputProps={InputProps}
        style={style}
      />
    </div>
  );
};