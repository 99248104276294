import React from "react";
import "./FinalProcess.scss";
import icon from "./../../assets/email.png";
import { Button1, Button } from "../../utils/Buttons/Buttons";
import {useHistory} from 'react-router-dom'
function FinalProcess(props) {
   
  return (
    <div className="impronta-advice">
     <p className='text-1'>
        Registro <br></br>Exitoso
      </p>
      <p className='text-2'>
        RECIBE EN TU <br></br>CORREO
      </p>
      <img src={icon} alt="icon" />
      <p className='text-3'>
        Documentos e información de la transacción.
      </p>
      <p className='text-4'>
        Si por algún motivo no esta el correo en <br></br> tu bandeja de
        entrada revisa la carpeta <br></br> de spam
      </p>
     
      <div style={{marginTop: "2em" }}>
        <Button  text="ENVIAR REGISTRO" onClick={()=>props.sendEmailFinal()} width="177px" height="19%"></Button>
      </div>
    </div>
  );
}
export default FinalProcess;
