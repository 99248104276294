import React from 'react';
import './ResumeEnter.scss'
import {TextFieldStyle2} from "./../../utils/TextFieldStyle/TextFieldStyle";
import { Button2, ButtonStyle } from "../../utils/Buttons/Buttons";
import {useHistory} from 'react-router-dom';

function ResumeEnter(props){

  const history = useHistory();
  
  const NextOptions= () => {
    history.push('./sent'+ history.location.search);
    props.sendEmailFinalINS();

  };
    return(
      props.userdata!=null&&  <div className="container">
            <p className="title">RESUMEN<br></br>
DE TU SEGURO</p>
<div className="container-inputs">
<div style={{ marginTop: "3em" }}>
        <TextFieldStyle2 label="Tipo de identificación" onChange={(e)=>{console.log(e.target.value)}}  value={"CC"}/>
      </div>
      <div style={{ marginTop: "3em" }}>
        <TextFieldStyle2 label="Identificación" onChange={(e)=>{console.log(e.target.value)}}  value={props.userdata["number_identification"]} />
      </div>
      <div style={{ marginTop: "3em" }}>
        <TextFieldStyle2 label="Nombre Completo" onChange={(e)=>{console.log(e.target.value)}}  value={props.userdata["name"]} />
      </div>
      
      <div style={{ marginTop: "3em" }}>
        <TextFieldStyle2 label="Fecha de Nacimiento" onChange={(e)=>{console.log(e.target.value)}}  value={props.userdata["fecha_nac"]} />
      </div>
      <div style={{ marginTop: "3em" }}>
        <TextFieldStyle2 label="Edad" onChange={(e)=>{console.log(e.target.value)}}  value={props.userdata["edad"]} />
      </div>
      <div style={{ marginTop: "3em" }}>
        <TextFieldStyle2 label="Destino viaje" onChange={(e)=>{console.log(e.target.value)}}  value={props.userdata["destino"]} />
      </div>
      <div style={{ marginTop: "3em" }}>
        <TextFieldStyle2 label="Rango seguro" onChange={(e)=>{console.log(e.target.value)}}  value={props.userdata["date"]} />
      </div>
    
 
</div>
<div style={{ margin: "2.2em" }}>
        <ButtonStyle
          text="SIGUIENTE"
          onClick={() => NextOptions()}
          width="177px"
          height="5vh"
        />
      </div>
        </div>
    )
}
export default ResumeEnter;