import React from "react";
import "./GeneralData.scss";
import car from "./../../assets/car.png";
import car2 from "./../../assets/AUTO2 1.png";
import { Row } from "react-bootstrap";
import { Button } from "./../../utils/Buttons/Buttons";
import {useHistory} from 'react-router-dom'

function GeneralData() {
    const history = useHistory()
    const NextTecno=()=>{
        history.push('./tecno'+ history.location.search)
    }
  return (
    <div className="general-style">
      <p className="title">
        Información general<br></br>
        del vehículo
      </p>
      <img src={car} alt="img" />
      {/* ---------------------------------CONTAINER 1 ---------------------------------- */}
      <div className="div-container">
        <div className="div-row1">
          <Row>
            <p>MARCA :</p>
          </Row>
          <Row>
            <p> LINEA :</p>
          </Row>
          <Row>
            <p>MODELO :</p>
          </Row>
          <Row>
            <p>COLOR :</p>
          </Row>
          <Row>
            <p>CILINDRAJE :</p>
          </Row>
          <Row>
            <p>TIPÓ DE CARROCERÍA :</p>
          </Row>
        </div>
        <div className="div-row2">
          <Row>
            <p>CHEVROLET</p>
          </Row>
          <Row>
            <p>AVEO LIMITED</p>
          </Row>
          <Row>
            <p>2009</p>
          </Row>
          <Row>
            <p>PLATA ESCUNA</p>
          </Row>
          <Row>
            <p>1600</p>
          </Row>
          <Row>
            <p>COUPE</p>
          </Row>
        </div>
      </div>
      <p className="info">
        INFORMACIÓN INTERNA<br></br>
        DEL VEHÍCULO
      </p>
      {/* ---------------------------------CONTAINER 2 ---------------------------------- */}
      <div className="div-container2">
        <div className="div-row1">
          <Row>
            <p>No DE MOTOR :</p>
          </Row>
          <Row>
            <p>No DE CHASIS :</p>
          </Row>
          <Row>
            <p>No DE SERIE :</p>
          </Row>
          <Row>
            <p>
              FECHA DE <br></br> MATRÍCULA
            </p>
          </Row>
        </div>
        <div className="div-row2">
          <Row>
            <p>F16D3146651</p>
          </Row>
          <Row>
            <p>KL1TJ23689B248267</p>
          </Row>
          <Row>
            <p>KL1TJ23689B248267</p>
          </Row>
          <Row>
            <p>
              (DD/MM/AAAA)<br></br>
              09/10/2008
            </p>
          </Row>
        </div>
      </div>
      <div className='txt-second-slide'>
      <p className='title2'>AUTORIDAD DE TRANSITO</p>
      <p className='subtitle2'>SMD - BOGOTÁ .C</p>
      </div>

     
      {/* ---------------------------------CONTAINER 3 ---------------------------------- */}
      <div className="div-container3">
        <div className="div-row1">
          <Row>
            <p>
              GRAVÁMENES<br></br>
              DE PROPIEDAD:
            </p>
          </Row>
          <Row>
            <p>CLÁSICO O ANTIGUO:</p>
          </Row>
          <Row>
            <p>NÚMERO DE PUERTAS :</p>
          </Row>
          <Row>
            <p>
              CAPACIDAD DE <br></br> PASAJEROS SENTADOS :
            </p>
          </Row>
          <Row>
            <p>NÚMERO DE EJES :</p>
          </Row>
        </div>
        <div className="div-row2">
          <Row>
            <p>NO</p>
          </Row>
          <Row>
            <p>NO</p>
          </Row>
          <Row>
            <p>03</p>
          </Row>
          <Row>
            <p>05</p>
          </Row>
          <Row>
            <p>02</p>
          </Row>
        </div>
      </div>

      <img src={car2} alt="img" />
      {/* ---------------------------------CONTAINER 4 ---------------------------------- */}
      <div className="div-container4">
        <div className="div-row1">
          <Row>
            <p>
              TIPO DE<br></br> COMBUSTIBLE :
            </p>
          </Row>
          <Row>
            <p>
              REGRABACIÓN<br></br>
              DE SERIE :
            </p>
          </Row>
          <Row>
            <p>
              REGRABACIÓN<br></br>
              DE CHASIS :
            </p>
          </Row>
        </div>
        <div className="div-row2">
          <Row>
          
            <p>GASOLINA</p>
          </Row>
          <Row>
  
            <p>NO</p>
          </Row>
          <Row>
            <p>NO</p>
          </Row>
        </div>
      </div>

      {/* ---------------------------------BUTTON NEXT---------------------------------- */}

      <div style={{margin:'2em 0em 3em 0em'}}>
        <Button
          text="SIGUIENTE"
         onClick={() => NextTecno()}
          width="177px"
          height="4.2vh"
        />
      </div>
    </div>
  );
}
export default GeneralData;
