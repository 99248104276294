import React, { useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import Screen1 from "././components/Screen1/Screen1";
import ScreenOptions from "././components/ScreenOptions/ScreenOptions";
import EnterData from "././components/EnterData/EnterData";
import GeneralData from "././components/GeneralData/GeneralData";
import Requirements from "././components/Requirements/Requirements";
import ImprontaAdvice from "././components/ImprontaAdvice/ImprontaAdvice";

import TomaFront from "./components/TomaFront/TomaFront";
import TomaBack from "./components/TomaBack/TomaBack";
import { infoMessage } from "./api/errorHandler";

import Tecno from "././components/Tecno/Tecno";
import VerifyData from "././components/VerifyData/VerifyData";
import Payment from "././components/Payment/Payment";
// import FormRequest from "./components/FormRequest/FormRequest";
import FormApi from "././components/FormData/FormApi";
// import VideoStart from './components/VideoStart/VideoStart'
import Api from "./api/api.js";
import { useHistory } from "react-router-dom";
import VideoContract from "./components/VideoContract/VideoContract";
import ImprontaContract from "./components/ImprontaContract/ImprontaContract";
import FinalProcess from "./components/FinalProcess/FinalProcess";
// ----------------------------INS_SCREENS---------------------------------------
import InsScreen1 from "./components/InsScreen1/InsScreen1";
import DestinyScreen from "./components/DestinyScreen/DestinyScreen";
import SliderSecurity1 from "./components/SliderSecurity/SliderSecurity1";
import SliderSecurity2 from "./components/SliderSecurity/SliderSecurity2";
import ResumeEnter from "./components/ResumeEnter/ResumeEnter";
import SentSecurity from "./components/SentSecurity/SentSecurity";
// -------------------------------------DUMMIES-------------------------------------------------------
import ScreenComponents from "./Dummies/ScreenComponents/ScreenComponents";
import InitialForm from "./Dummies/InitialForm/InitialForm";
import FinalForm from "./Dummies/InitialForm/FinalForm";
import AuthCallback from "./Dummies/InitialForm/AuthCallback";
import WelcomeFormScreen from "./Dummies/InitialForm/WelcomeFormScreen";

import WelcomeFormScreen2 from "./Dummies/InitialForm/WelcomeFormScreen2";
import InitialForm2 from "./Dummies/InitialForm/InitialForm2";
import FinalForm2 from "./Dummies/InitialForm/FinalForm2";

// -------------------------------------COOCIQUE-------------------------------------------------------
import CoociqueIntro from "./components/CoociqueIntro/CoociqueIntro";
import CoociqueIntro2 from "./components/CoociqueIntro2/CoociqueIntro2";
import CoociqueData from "./components/CoociqueData/CoociqueData";
import CoociqueFirma from "./components/CoociqueFirma/CoociqueFirma";
import CoociqueFinal from "./components/CoociqueFinal/CoociqueFinal";
import DummiesApi from "./api/dummies_api";
import ABienvenido from "./Comercial/ABienvenido/ABienvenido";
import BRegistro from "./Comercial/BRegistro/BRegistro";
import CGenialIdentificacion from "./Comercial/CGenialIdentificacion/CGenialIdentificacion";
import DVamosIdentificar from "./Comercial/DVamosIdentificar/DVamosIdentificar";
import EFinalizarIdentidad from "./Comercial/EFinalizarIdentidad/EFinalizarIdentidad";
import FEstamosValidando from "./Comercial/FEstamosValidando/FEstamosValidando";
import GHola from "./Comercial/GHola/GHola";
import HQueDeseaHacer from "./Comercial/HQueDeseaHacer/HQueDeseaHacer";
import IVamosCuentaAhorros from "./Comercial/IVamosCuentaAhorros/IVamosCuentaAhorros";
import KIngresaCuentaAhorros from "./Comercial/KIngresaCuentaAhorros/KIngresaCuentaAhorros";
import LVerifiquemosCuentaAhorros from "./Comercial/LVerifiquemosCuentaAhorros/LVerifiquemosCuentaAhorros";
import MFinalCuentaAhorros from "./Comercial/MFinalCuentaAhorros/MFinalCuentaAhorros";
import JSelecciona from "./Comercial/JSelecciona/JSelecciona";
import NVamosSeguro from "./Comercial/NVamosSeguro/NVamosSeguro";
import OVamosAfiliacion from "./Comercial/OVamosAfiliacion/OVamosAfiliacion";
import QVerificacionAfiliacion from "./Comercial/QVerificacionAfiliacion/QVerificacionAfiliacion";
import RIngresaAfiliacion from "./Comercial/RIngresaAfiliacion/RIngresaAfiliacion";
import SFinalAfiliacion from "./Comercial/SFinalAfiliacion/SFinalAfiliacion";
import TVamosCredito from "./Comercial/TVamosCredito/TVamosCredito";
import UIngresaCredito from "./Comercial/UIngresaCredito/UIngresaCredito";
import VAdjunta from "./Comercial/VAdjunta/VAdjunta";
import WVisualizar from "./Comercial/WVisualizar/WVisualizar";
import XVerifiquemosCredito from "./Comercial/XVerifiquemosCredito/XVerifiquemosCredito";
import YFinalCredito from "./Comercial/YFinalCredito/YFinalCredito";
import VAdjuntaImg from "./Comercial/VAdjuntaImg/VAdjuntaImg";
// import Documents from "./backOffice-1.0/Documents/Documents";
// import UploadDocuments from "./backOffice-1.0/VAdjunta/UploadDocuments";
// import ViewDocuments from "./backOffice-1.0/VAdjunta/ViewDocuments";
import InsIntro from "./components/INS_NEW/ins_intro";
import InsRegisterIntro from "./components/INS_NEW/INS_register_1";
import InsForm1 from "./components/INS_NEW/InsForm1";
import InsForm2 from "./components/INS_NEW/InsForm2";
import InsFormIntro from "./components/INS_NEW/insFormIntro";
import InsForm3 from "./components/INS_NEW/insForm3";
import InsResumen1 from "./components/INS_NEW/insResumen1";
import InsFormPay from "./components/INS_NEW/insFormPay";
import InsFormEnd from "./components/INS_NEW/insFormEnd";
import InsFormPayCallback from "./components/INS_NEW/insFormPayCallback";
import InsCoreidCallback from "./components/INS_NEW/insCoreIdCallback";
import ElectronicSignatureRedirect from "./Dummies/InitialForm/ElectronicSignatureRedirect";
import Form from "./magic_forms_2/formComponent/Form";
import NewDummiesIntro from "./magic_forms_2/dummiesComponent/NewDummies1";
import NewDummiesCoreIdCallback from "./magic_forms_2/dummiesComponent/NewDummiesCoreIdCallback";
import NewDummiesMagicCallback from "./magic_forms_2/dummiesComponent/NewDummiesMagicCallback";
import NewDummiesElecSigCallback from "./magic_forms_2/dummiesComponent/NewDummiesElecSigCallback";
import NewDummiesSigOnly from "./magic_forms_2/dummiesComponent/NewDummiesSigOnly";
import HyperFlowCoreIdCallback from "./hyperFlow/hyperFlowCoreIdCallback";
import HyperFlowContinue from "./hyperFlow/hyperFlowContinue";
import HyperFlowMagicFormsCallback from "./hyperFlow/hyperFlowMagicFormsCallback";
import HyperFlowShowMessage from "./hyperFlow/hyperFlowShowMessage";
import { Explanation } from "./magic_forms_2/agentComponents/Explanation";
import { ConfirmPause } from "./magic_forms_2/formComponent/ConfirmPause";
import { AsesorCard } from "./asesor/AsesorCard";
import { AsesorCreation } from "./asesor/AsesorCreation";
import { CoreIdDummy } from "./Dummies/coreIdDummy/coreIdDummy";
import { InfoUsuarioDummy } from "./Dummies/coreIdDummy/infoUsuarioDummy/infoUsuarioDummy";
import { FaceUsuarioDummy } from "./Dummies/coreIdDummy/faceUsuarioDummy/faceUsuarioDummy";
import { IdentidadDigitalUsuarioDummy } from "./Dummies/coreIdDummy/identidadDigitalUsuarioDummy/identidadDigitalUsuarioDummy";
import MfEditor from "./mfEditor/mfEditor";
import HyperFlowElecSigCallback from "./hyperFlow/hyperFlowElecSigCallback";
import HyperFlowCallback from "./hyperFlow/hyperFlowHyperFlowCallback";
import HyperFlowPathSelect from "./hyperFlow/hyperFlowPathSelect";
import HyperFlowMultiElecSigCallback from "./hyperFlow/hyperFlowMultiElecSigCallback";
import HyperFlowOtp from "./hyperFlow/hyperFlowOtp";
import { RedirectContent } from "./magic_forms_2/formComponent/components/RedirectContent";

const data = {
  a: {
    prima: "37.06",
    valor: "63.525",
  },
  c: {
    prima: "116.58",
    valor: "132.275",
  },
  d: {
    prima: "123.52",
    valor: "132.275",
  },
};
function AppRouter() {
  const history = useHistory();
  const [placa, setPlaca] = useState("");
  const [number, setNumber] = useState("");
  const [registryid, setRegistry] = useState("");
  const [code, setCode] = useState("");

  const api = new Api();

  const sendData = (ni, p) => {
    setNumber(ni);
    setPlaca(p);

    api.create_user(parseInt(ni), p, code).then((e) => {
      history.push("/data" + history.location.search);
    });
  };

  const redirectmfa = () => {
    api.getRedirectMFA(parseInt(number));
  };

  const SendInstructions = () => {
    api.sendemail_improntas(parseInt(number));
  };
  const SendPago = () => {
    api.sendemail_pago(parseInt(number));
  };
  const sendEmailFinal = () => {
    api.sendEmailFinal(parseInt(number));
    infoMessage("Soportes enviados correctamente a tu correo.");
  };
  const passContract = () => {
    history.push("/contractinit" + history.location.search);
  };

  const registry = () => {
    var regid = getParameterByName("registrationId").toString();
    setRegistry(regid);
    api.create_registration(regid).then((user) => {
      setNumber(user["initialData"]["identification"]);
    });
  };

  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };
  const registryNi = () => {
    var ni = parseInt(getParameterByName("ni"));
    setNumber(ni);
  };
  const sendVideo = (data) => {
    api.sendVideo(parseInt(number), data);
  };

  //INS
  const [req, onChangeReq] = useState({
    destino: "",
    start_date: new Date().toString().split("T")[0],
    end_date: new Date().toString().split("T")[0],
    type_plan: "basico",
    prima: "37.06",
    valor: "63.525",
    code: "",
  });
  const [selectedValue, setSelectedValue] = useState("a");
  const [user, setUser] = useState("");
  const [userdata, setUserdata] = useState(null);
  const [registrationId, setregistrationId] = useState("");

  const changesetSelectedValue = (e) => {
    setSelectedValue(e);
    var temp = req;
    temp["prima"] = data[e]["prima"];
    temp["valor"] = data[e]["valor"];
    onChangeReq(temp);
  };
  const redirectmfains = () => {
    var temp = req;
    temp["code"] = code;

    console.log(temp);
    api.getRedirectMFAINS(temp);
  };
  const sendEmailFinalINS = () => {
    api.sendEmailINS(user);
    infoMessage("Soportes enviados correctamente a tu correo.");
  };

  //COOCIQUE

  const [dataCOO, setDataCOO] = useState({
    number_identification: "",
    email: "",
    phone: "",
  });

  const redirectmfaCOO = () => {
    var temp = dataCOO;
    temp["code"] = code;
    infoMessage("Procesando petición.");
    api.getRedirectMFACOO(temp);
  };
  const sendEmailFinalCOO = () => {
    var userparam = getParameterByName("user");
    var registryparam = getParameterByName("registrationId");
    if (userparam === null) {
      infoMessage("Url incorrecta, no es posible continuar con el flujo.");
    } else {
      setUser(userparam);
      if (registryparam === null) {
        infoMessage("Url incorrecta, no es posible continuar con el flujo.");
      } else {
        api.sendEmailCOO(userparam, registryparam);
        infoMessage("Validando información.");
      }
    }
  };

  // Demo comercial
  const [cahorros, setCAhorros] = useState({
    nombre: "",
    direccion: "",
    correo: "",
    pais: "",
    tipo: "",
    tipoingresos: "ASALARIADO",
    monto: "",
    ocupacion: "",
  });
  const [ccredito, setCredito] = useState({
    nombre: "",
    direccion: "",
    correo: "",
    monto: "",
    sector: "",
    salario: "",
    tipocredito: "VIVIENDA",
  });
  const [cdocument, setCDocument] = useState({ type: "", value: "" });

  const redirectmfaCOM = () => {
    var temp = dataCOO;
    temp["code"] = code;
    api.getRedirectMFACOM(code);
  };
  const redirectmfaaCOM = () => {
    var temp = code;
    api.getRedirectMFAACOM(temp);
  };
  const redirectfirmaCOM = (doc) => {
    var temp = dataCOO;
    temp["code"] = code;
    api.sendEmailCOM(code, doc);
  };
  const dataUpdate = (response, data, method) => {
    var temp = data;
    temp["correo"] = response["correo"];
    temp["nombre"] = response["nombre"];
    temp["direccion"] = response["direccion"];
    temp["pais"] = response["ciudad"];
    method(temp);
  };

  const getuserCOM = (dataUser, setDataUser) => {
    var temp = code;
    api.getusercomercial(temp, dataUser, setDataUser, dataUpdate);
  };

  useEffect(() => {
    if (code == "") {
      if (getParameterByName("code") != null) {
        setCode(getParameterByName("code").toString());
      }

      var linkdata = [
        "/ABienvenido",
        "/BRegistro",
        "/CGenialIdentificacion",
        "/DVamosIdentificar",
        "/EFinalizarIdentidad",
        "/FEstamosValidando",
        "/GHola",
        "/HQueDeseaHacer",
        "/IVamosCuentaAhorros",
        "/JSelecciona",
        "/KIngresaCuentaAhorros",
        "/LVerifiquemosCuentaAhorros",
        "/MFinalCuentaAhorros",
        "/NVamosSeguro",
        "/OVamosAfiliacion",
        "/QVerificacionAfiliacion",
        "/RIngresaAfiliacion",
        "/SFinalAfiliacion",
        "/TVamosCredito",
        "/UIngresaCredito",
        "/VAdjunta",
        "/VAdjuntaImg",
        "/WVisualizar",
        "/XVerifiquemosCredito",
        "/YFinalCredito",
        "/payfinal",
        "/generic",
        "/ins2",
        "/slider1",
        "/slider2",
        "/resume",
        "/sent",
      ];
      console.log(window.location.pathname);

      if (
        linkdata.includes(window.location.pathname) &&
        getParameterByName("code") == null
      ) {
        infoMessage("Url incorrecta, no es posible continuar con el flujo.");
      }
    }

    if (window.location.pathname == "/email") {
      if (getParameterByName("registrationId") == null) {
        history.push("/enter" + history.location.search);
      }
      registry();
    }
    if (
      window.location.pathname == "/frmfinal" ||
      window.location.pathname == "/contract" ||
      window.location.pathname == "/toma1"
    ) {
      if (getParameterByName("ni") == null) {
        infoMessage("Url incorrecta, no es posible continuar con el flujo.");
      } else {
        registryNi();
      }
    }
    if (window.location.pathname == "/payfinal" && userdata == null) {
      var userparam = getParameterByName("user");
      var registryparam = getParameterByName("registrationId");
      var codeparam = getParameterByName("codet");
      if (userparam === null) {
        infoMessage("Url incorrecta, no es posible continuar con el flujo.");
      } else {
        setUser(userparam);
        if (registryparam === null) {
          infoMessage("Url incorrecta, no es posible continuar con el flujo.");
        } else {
          setregistrationId(registryparam);

          api.getInfoINS(
            { registrationId: registryparam, code: codeparam, user: userparam },
            setUserdata
          );
        }
      }
    }

    //Color

    var resultCOO = [
      "/coociqueintro",
      "/coociqueintro2",
      "/coociquedata",
      "/coociquefirma",
      "/coociquefinal",
    ].indexOf(window.location.pathname);

    if (resultCOO >= 0) {
      document.body.style.background = "#003fac";
    }
  });

  return (
    <Switch>
      <Route exact path="/" component={() => <Screen1 />} />
      <Route exact path="/option" component={() => <ScreenOptions />} />
      <Route
        exact
        path="/enter"
        component={() => <EnterData sendData={sendData} />}
      />
      <Route exact path="/data" component={() => <GeneralData />} />
      <Route exact path="/tecno" component={() => <Tecno />} />
      <Route
        exact
        path="/requirement"
        component={() => <Requirements redirectmfa={redirectmfa} ni={number} />}
      />
      <Route
        exact
        path="/email"
        component={() => (
          <ImprontaAdvice
            SendInstructions={SendInstructions}
            registry={registry}
          />
        )}
      />
      <Route
        exact
        path="/toma1"
        component={() => <TomaFront send={sendVideo} />}
      />
      <Route
        exact
        path="/toma2"
        component={() => <TomaBack send={sendVideo} />}
      />

      <Route
        exact
        path="/verify"
        component={() => <VerifyData SendPago={SendPago} />}
      />
      <Route exact path="/pay" component={() => <Payment />} />
      {/* <Route exact path="/formreq" component={() => <FormRequest />} /> */}
      {/*  <Route
        exact
        path="/payfinal"
        component={() => <FormApi next={passContract} />}
    />*/}
      <Route
        exact
        path="/contractinit"
        component={() => <ImprontaContract ni={number} />}
      />
      <Route
        exact
        path="/contract"
        component={() => <VideoContract ni={number} api={api} />}
      />
      <Route
        exact
        path="/frmfinal"
        component={() => <FinalProcess sendEmailFinal={sendEmailFinal} />}
      />

      <Route exact path="/generic" component={() => <InsScreen1 />} />
      <Route
        exact
        path="/ins2"
        component={() => <DestinyScreen req={req} onChangeReq={onChangeReq} />}
      />
      <Route
        exact
        path="/slider1"
        component={() => (
          <SliderSecurity1
            selectedValue={selectedValue}
            setSelectedValue={changesetSelectedValue}
          />
        )}
      />
      <Route
        exact
        path="/slider2"
        component={() => (
          <SliderSecurity2
            redirectmfa={redirectmfains}
            selectedValue={selectedValue}
            setSelectedValue={changesetSelectedValue}
          />
        )}
      />

      <Route
        exact
        path="/payfinal"
        component={() => (
          <ResumeEnter
            sendEmailFinalINS={sendEmailFinalINS}
            userdata={userdata}
          />
        )}
      />
      <Route exact path="/sent" component={() => <SentSecurity />} />

      {/* <Route exact path="/coociqueintro" component={() => <CoociqueIntro />} />*/}

      {/*  <Route exact path="/coociqueintro2" component={() => <CoociqueIntro2  redirectmfaCOO={redirectmfaCOO}/>} />*/}

      {/*  <Route exact path="/coociquedata" component={() => <CoociqueData data={ dataCOO} setData={setDataCOO}/>} />*/}

      {/*  <Route exact path="/coociquefirma" component={() => <CoociqueFirma sendEmailFinalCOO={sendEmailFinalCOO}/>} />*/}

      {/*  <Route exact path="/coociquefinal" component={() => <CoociqueFinal />} />*/}

      {/* <Route exact path="/video" component={() => <VideoStart />} /> */}

      {/* ------------------------DUMMIES ROUTES--------------------------------- */}
      <Route exact path="/dummies" component={() => <ScreenComponents />} />

      {/* ------------------------ CHAZKI ROUTES -------------------------------- */}

      <Route path="/welcomeForm2">
        <WelcomeFormScreen2 />
      </Route>

      <Route path="/initForm2">
        <InitialForm2 />
      </Route>

      <Route path="/finalForm2">
        <FinalForm2 />
      </Route>

      {/* Old Routes */}
      <Route path="/welcomeForm">
        <WelcomeFormScreen />
      </Route>

      <Route path="/introForm">
        <InitialForm />
      </Route>

      <Route path="/finalForm">
        <FinalForm />
      </Route>

      <Route path="/electronicSignatureRedirect">
        <ElectronicSignatureRedirect />
      </Route>

      <Route path="/authCallback">
        <AuthCallback />
      </Route>

      {/* INS NEW ROUTES */}
      {/* INS NEW ROUTES */}
      {/* INS NEW ROUTES */}
      {/* INS NEW ROUTES */}
      <Route path="/insNew">
        <InsIntro />
      </Route>

      <Route path="/insRegister1">
        <InsRegisterIntro />
      </Route>

      <Route path="/insCoreIdCallback">
        <InsCoreidCallback />
      </Route>

      <Route path="/insFormIntro">
        <InsFormIntro />
      </Route>

      <Route path="/insForm1">
        <InsForm1 />
      </Route>

      <Route path="/insForm2">
        <InsForm2 />
      </Route>

      <Route path="/insForm3">
        <InsForm3 />
      </Route>

      <Route path="/insResumen1">
        <InsResumen1 />
      </Route>

      <Route path="/insFormPay">
        <InsFormPay />
      </Route>

      <Route path="/insPayCallback">
        <InsFormPayCallback />
      </Route>

      <Route path="/insFormEnd">
        <InsFormEnd />
      </Route>

      {/* Magic Forms 2.0!!!!! */}
      {/* Magic Forms 2.0!!!!! */}
      {/* Magic Forms 2.0!!!!! */}
      {/* Magic Forms 2.0!!!!! */}
      {/* Magic Forms 2.0!!!!! */}
      <Route path="/magicForms2">
        <Form />
      </Route>

      {/* Agent Routes */}
      <Route path="/explanation">
        <Explanation />
      </Route>

      {/* Confirm Pausa */}
      <Route path="/pauseConfirmation">
        <ConfirmPause />
      </Route>

      {/* Dummies Neww ROUTESSSSSSSSSSSSSSSSS */}
      {/* Dummies Neww ROUTESSSSSSSSSSSSSSSSS */}
      {/* Dummies Neww ROUTESSSSSSSSSSSSSSSSS */}
      {/* Dummies Neww ROUTESSSSSSSSSSSSSSSSS */}
      {/* Dummies Neww ROUTESSSSSSSSSSSSSSSSS */}

      <Route path="/newDummiesInitial">
        <NewDummiesIntro />
      </Route>

      <Route path="/newDummiesCoreIdCallback">
        <NewDummiesCoreIdCallback />
      </Route>

      <Route path="/newDummiesMagicCallback">
        <NewDummiesMagicCallback />
      </Route>

      <Route path="/newDummiesElecSigCallback">
        <NewDummiesElecSigCallback />
      </Route>

      <Route path="/dummiesElecSigOnly">
        <NewDummiesSigOnly />
      </Route>

      <Route path="/mfEditor">
        <MfEditor />
      </Route>

      {/* HyperFlowwwwwwwwwwwwwww ROUTESSSSSSSSSSSSSSSSS */}
      {/* HyperFlowwwwwwwwwwwwwww ROUTESSSSSSSSSSSSSSSSS */}
      {/* HyperFlowwwwwwwwwwwwwww ROUTESSSSSSSSSSSSSSSSS */}
      {/* HyperFlowwwwwwwwwwwwwww ROUTESSSSSSSSSSSSSSSSS */}
      {/* HyperFlowwwwwwwwwwwwwww ROUTESSSSSSSSSSSSSSSSS */}
      <Route path="/hyperFlow/coreIdCallBack">
        <HyperFlowCoreIdCallback />
      </Route>

      <Route path="/hyperFlow/continueFlow">
        <HyperFlowContinue />
      </Route>
      <Route path="/hyperFlow/magicFormsCallback">
        <HyperFlowMagicFormsCallback />
      </Route>
      <Route path="/hyperFlow/elecSigCallback">
        <HyperFlowElecSigCallback />
      </Route>
      <Route path="/hyperFlow/showMessage">
        <HyperFlowShowMessage />
      </Route>

      <Route path="/hyperFlowAsyncCallback">
        <HyperFlowCallback />
      </Route>
      <Route path="/hyperFlow/pathSelect">
        <HyperFlowPathSelect />
      </Route>
      <Route path="/hyperFlow/elecSigMultiCallback">
        <HyperFlowMultiElecSigCallback />
      </Route>
      <Route path="/hyperFlow/otp">
        <HyperFlowOtp />
      </Route>
      <Route path="/magicForms/redirectContent">
        <RedirectContent />
      </Route>

      {/* ------------------------COMERCIAL ROUTES--------------------------------- */}
      <Route exact path="/ABienvenido" component={() => <ABienvenido />} />
      <Route exact path="/BRegistro" component={() => <BRegistro />} />
      <Route
        exact
        path="/CGenialIdentificacion"
        component={() => <CGenialIdentificacion redirect={redirectmfaCOM} />}
      />
      <Route
        exact
        path="/DVamosIdentificar"
        component={() => <DVamosIdentificar redirect={redirectmfaaCOM} />}
      />
      <Route
        exact
        path="/EFinalizarIdentidad"
        component={() => <EFinalizarIdentidad />}
      />
      <Route
        exact
        path="/FEstamosValidando"
        component={() => <FEstamosValidando />}
      />

      <Route exact path="/GHola" component={() => <GHola />} />
      <Route
        exact
        path="/HQueDeseaHacer"
        component={() => <HQueDeseaHacer />}
      />

      <Route
        exact
        path="/IVamosCuentaAhorros"
        component={() => <IVamosCuentaAhorros />}
      />
      <Route
        exact
        path="/JSelecciona"
        component={() => (
          <JSelecciona
            data={cahorros}
            setData={setCAhorros}
            getData={() => getuserCOM(cahorros, setCAhorros)}
          />
        )}
      />
      <Route
        exact
        path="/KIngresaCuentaAhorros"
        component={() => (
          <KIngresaCuentaAhorros data={cahorros} setData={setCAhorros} />
        )}
      />
      <Route
        exact
        path="/LVerifiquemosCuentaAhorros"
        component={() => (
          <LVerifiquemosCuentaAhorros
            redirect={() => redirectfirmaCOM("1")}
            data={cahorros}
            setData={setCAhorros}
          />
        )}
      />
      <Route
        exact
        path="/MFinalCuentaAhorros"
        component={() => <MFinalCuentaAhorros />}
      />

      <Route exact path="/NVamosSeguro" component={() => <NVamosSeguro />} />

      <Route
        exact
        path="/OVamosAfiliacion"
        component={() => <OVamosAfiliacion />}
      />
      <Route
        exact
        path="/QVerificacionAfiliacion"
        component={() => <QVerificacionAfiliacion />}
      />
      <Route
        exact
        path="/RIngresaAfiliacion"
        component={() => (
          <RIngresaAfiliacion
            data={cahorros}
            setData={setCAhorros}
            redirect={() => redirectfirmaCOM("3")}
          />
        )}
      />
      <Route
        exact
        path="/SFinalAfiliacion"
        component={() => <SFinalAfiliacion />}
      />

      <Route exact path="/TVamosCredito" component={() => <TVamosCredito />} />
      <Route
        exact
        path="/UIngresaCredito"
        component={() => (
          <UIngresaCredito
            data={ccredito}
            setData={setCredito}
            getData={() => getuserCOM(ccredito, setCredito)}
          />
        )}
      />
      <Route
        exact
        path="/VAdjunta"
        component={() => <VAdjunta data={cdocument} setData={setCDocument} />}
      />
      <Route
        exact
        path="/VAdjuntaImg"
        component={() => (
          <VAdjuntaImg data={cdocument} setData={setCDocument} />
        )}
      />
      <Route
        exact
        path="/WVisualizar"
        component={() => (
          <WVisualizar data={cdocument} setData={setCDocument} />
        )}
      />
      <Route
        exact
        path="/XVerifiquemosCredito"
        component={() => (
          <XVerifiquemosCredito
            redirect={() => redirectfirmaCOM("4")}
            data={ccredito}
            setData={setCredito}
          />
        )}
      />
      <Route exact path="/YFinalCredito" component={() => <YFinalCredito />} />

      {/* <Route
        exact
        path="/getformsuser"
        component={() => <Documents api={api} />}
      />
      <Route
        exact
        path="/demoUpload"
        component={() => <UploadDocuments api={api} />}
      />
      <Route
        exact
        path="/demoViewDocument"
        component={() => <ViewDocuments api={api} />}
      /> */}

      {/* ------------------------ASESOR ROUTES--------------------------------- */}
      <Route path="/asesores">
        <AsesorCard />
      </Route>
      <Route path="/createAsesor">
        <AsesorCreation />
      </Route>
      {/* ------------------------ASESOR ROUTES END--------------------------------- */}
      <Route path="/coreIdDummy">
        <CoreIdDummy />
      </Route>
      <Route path="/infoUsuarioDummy">
        <InfoUsuarioDummy />
      </Route>
      <Route path="/faceUsuarioDummy">
        <FaceUsuarioDummy />
      </Route>
      <Route path="/identidadDigitalUsuarioDummy">
        <IdentidadDigitalUsuarioDummy />
      </Route>
    </Switch>
  );
}
export default AppRouter;
