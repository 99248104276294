import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import {useHistory} from 'react-router-dom'


import "./QVerificacionAfiliacion.scss";

function QVerificacionAfiliacion() {
  const history = useHistory();
  
  
  const NextOptions1= () => {
    history.push('./RIngresaAfiliacion'+ history.location.search)
  };
  
  return (
    <div className="screen1">
      <div className="box_one" style={{marginBottom:'2em', marginTop: "20vh"}}>
        <p className="title2">Bienvenido al proceso  <br/>  de afiliación</p>
        <p className="title2">Recuerde que debe ser <br/> mayor de edad para poder <br/> afiliarte.</p>

      </div>

   
      <div style={{ marginTop: "3em" }}>
        <ButtonStyle
          text="CONTINUAR"
           onClick={() => NextOptions1()}
          width="177px"
          height="5vh"
        />
         </div>
       
    </div>
  );
}
export default QVerificacionAfiliacion;
