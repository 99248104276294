import React, { useState, useEffect } from "react";
import "./Screen1.scss";
import { Button } from "./../../utils/Buttons/Buttons";
import { useHistory } from "react-router-dom";
import ScreenLogo from "./ScreenLogo";
function Screen1() {
  let history = useHistory();
  const [seconds, setSeconds] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(seconds <= 1 ? seconds + 1 : seconds);
    }, 4000);
    // clearing interval

    return () => clearInterval(timer);
  });
  console.log(seconds, 'SECONDS')

  const NextOptions = () => {
    history.push("./option"+ history.location.search);
  };
  return (
    <>
      {seconds === 1 && <ScreenLogo />}
      {seconds === 2 &&(
        <div className="screen1">
          <div className="text1">
            <p>
              Selecciona el trámite <br></br>a realizar
            </p>
          </div>
          <div style={{ marginTop: "13em" }}>
            <Button
              text="CONTINUAR"
              onClick={() => NextOptions()}
              width="177px"
              height="5vh"
            />
          </div>
        </div>
      )}
    </>
  );
}
export default Screen1;
