import axios from "axios";

// const server = "http://localhost:8180";
// const server = "https://dev.hyperflow.anteia.co";
const server = "https://services.anteia.co";

axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

export const createAsesorAPI = async (params: CreateAsesorRequest) => {
  try {
    let response = await axios.post<CreateAsesorResponse>(
      `${server}/asesor/createAsesor`,
      params
    );
    return { asesorResponse: response.data, error: "" };
  } catch (err: any) {
    return { asesorResponse: null, error: err.response.data.message };
  }
};

export const getAsesorAPI = async (id: string) => {
  try {
    let response = await axios.get<ClientUserData>(
      `${server}/asesor/getAsesor/${id}`
    );
    return { asesorResponse: response.data, error: "" };
  } catch (err: any) {
    return { asesorResponse: null, error: "error" };
  }
};

export const getAsesorBrandConfigAPI = async (id: string) => {
  try {
    let response = await axios.get<BrandConfig>(
      `${server}/asesor/getBrandConfigByAsesorId/${id}`
    );
    return { brandConfigResponse: response.data, error: "" };
  } catch (err: any) {
    return { brandConfigResponse: null, error: err.response.data.message };
  }
};

export const getAsesorPicAPI = async (id: string) => {
  try {
    let response = await axios.get<AsesorPicResponse>(
      `${server}/asesor/getAsesorPic/${id}`
    );
    return { asesorResponse: response.data, error: "" };
  } catch (err: any) {
    return { asesorResponse: null, error: err.response.data.message };
  }
};

export const getFlujoAsesor = async (id: string) => {
  try {
    let response = await axios.get<StartUserFlowResponse>(
      `${server}/asesor/startFlowUser/${id}`
    );
    window.location.href = response.data.url;
  } catch (err: any) {
    console.log("check", err);
  }
};
export async function getMessageConfig(
  code: string,
  execFlowId: string
): Promise<ShowMessageConfigModel | null> {
  try {
    let response = await axios.get<ShowMessageConfigModel>(
      `${server}/hyperflow/flow/getShowMessageV2Config?code=${code}&execFlowId=${execFlowId}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.log("error status: " + response.status);
      throw Error("error status: " + response.status);
    }
  } catch (err: any) {
    console.log("check", err);
    // return {
    //   id: "CONFIAR_MESSAGE_TARJETA_APROBADA",
    //   clientId: "CONFIAR",
    //   hasConfetti: true,
    //   hasSadFace: false,
    //   isPopup: true,
    //   messages: [
    //     {
    //       message: "¡Felicitaciones!",
    //       color: "#7AC147",
    //       size: "24px",
    //       stateIds: [],
    //       fontWeight: "700",
    //     },

    //     {
    //       message:
    //         "Tenemos para ti un crédito aprobado. \n Dirígete a nuestra aplicación Confiar Móvil \n para obtenerlo.",
    //       color: "#000000",
    //       size: "16px",
    //       stateIds: [],
    //       fontWeight: "400",
    //     },
    //   ],
    //   continueMessage: "OK",
    // };
    return null;
  }
}

export async function getColorsByCodeId(code: string): Promise<BrandConfig> {
  try {
    let response = await axios.get<BrandConfig>(
      `${server}/hyperflow/flow/brandConfigByCode?code=${code}`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      console.log("error status: " + response.status);
      return {
        backgroundColor1: "#047bd6",
        backgroundColor2: "#000e1a",
        logoUrl: "",
        darkMode: true,
        primaryButtonColor:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
        secondaryButtonColor:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",

        font: null,
      };
    }
  } catch (err) {
    console.log("error: " + err);
    return {
      backgroundColor1: "#047bd6",
      backgroundColor2: "#000e1a",
      logoUrl: "",
      darkMode: true,
      primaryButtonColor:
        "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
      secondaryButtonColor:
        "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",

      font: null,
    };
  }
}

export async function getColorsByExecFlow(id: string): Promise<BrandConfig> {
  try {
    let response = await axios.get<BrandConfig>(
      `${server}/hyperflow/flow/brandConfigByExecFlow?execFlowId=${id}`
    );

    console.log(response);

    if (response.status === 200) {
      console.log("success");
      console.log(response.data);

      return response.data;
    } else {
      console.log("error status: " + response.status);
      return {
        backgroundColor1: "#047bd6",
        backgroundColor2: "#000e1a",
        logoUrl: "",
        darkMode: true,
        primaryButtonColor:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
        secondaryButtonColor:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
        font: null,
        failed: true,
      };
    }
  } catch (err) {
    console.log("error: " + err);
    return {
      backgroundColor1: "#047bd6",
      backgroundColor2: "#000e1a",
      logoUrl: "",
      darkMode: true,
      primaryButtonColor:
        "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
      secondaryButtonColor:
        "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
      font: null,
      failed: true,
    };
  }
}

export async function coreIdCallback(
  regId: string,
  execFlowId: string,
  stepId: string,
  error: string
): Promise<void> {
  try {
    let response = await axios.get(
      `${server}/hyperflow/flow/coreIdCallback?regId=${regId}&execFlowId=${execFlowId}&stepId=${stepId}&error=${error}`
    );

    if (response.status === 200) {
      console.log("coreIdCallback success");
      return;
    } else {
      console.log("coreIdCallback error status: " + response.status);
    }
  } catch (err) {
    console.log("coreIdCallback error: " + err);
    return;
  }
}

export async function coreIdCallbackWithToken(
  regId: string,
  execFlowId: string,
  stepId: string,
  error: string,
  token: string
): Promise<void> {
  try {
    let response = await axios.get(
      `${server}/hyperflow/flow/coreIdAuthCallback?regId=error&execFlowId=${execFlowId}&stepId=${stepId}&error=${error}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 200) {
      console.log("coreIdCallback success");
      return;
    } else {
      console.log("coreIdCallback error status: " + response.status);
    }
  } catch (err) {
    console.log("coreIdCallback error: " + err);
    return;
  }
}

export async function elecSigCallback(
  signatureId: string,
  execFlowId: string,
  stepId: string
): Promise<void> {
  try {
    let response = await axios.get(
      `${server}/hyperflow/flow/elecSigCallback?signatureId=${signatureId}&execFlowId=${execFlowId}&stepId=${stepId}`
    );

    if (response.status === 200) {
      console.log("ElecSigCallback success");
      return;
    } else {
      console.log("ElecSigCallback error status: " + response.status);
    }
  } catch (err) {
    console.log("ElecSigCallback error: " + err);
    return;
  }
}

export async function multiElecSigCallback(
  processId: string,
  execFlowId: string,
  stepId: string
): Promise<MultiElecSigResponse> {
  try {
    let response = await axios.get<MultiElecSigResponse>(
      `${server}/hyperflow/flow/multiElecSigCallback?processId=${processId}&execFlowId=${execFlowId}&stepId=${stepId}`
    );

    if (response.status === 200) {
      console.log("ElecSigCallback success");
      return response.data;
    } else {
      console.log("ElecSigCallback error status: " + response.status);
      return { confirmed: false };
    }
  } catch (err) {
    console.log("ElecSigCallback error: " + err);
    return { confirmed: false };
  }
}

export async function magicFormsCallback(
  filledFormId: string,
  execFlowId: string,
  stepId: string
): Promise<void> {
  try {
    let response = await axios.get(
      `${server}/hyperflow/flow/magicFormsCallback?filledFormId=${filledFormId}&execFlowId=${execFlowId}&stepId=${stepId}`
    );

    if (response.status === 200) {
      console.log("magicFormsCallback success");
      return;
    } else {
      console.log("magicFormsCallback error status: " + response.status);
    }
  } catch (err) {
    console.log("magicFormsCallback error: " + err);
    return;
  }
}

export async function hyperFlowAsyncCallback(
  code: string,
  parentId: string,
  execFlowId: string,
  currentStep: string
): Promise<HyperFlowCallbackResponse> {
  try {
    let response = await axios.get<HyperFlowCallbackResponse>(
      `${server}/hyperflow/flow/hyperFlowAsyncCallback?code=${code}&parentExecFlowId=${parentId}&executedFlow=${execFlowId}&currentStep=${currentStep}`
    );

    if (response.status === 200) {
      console.log("hyperflowAsync success");
      return response.data;
    } else {
      console.log("hyperflowAsync error status: " + response.status);
    }
  } catch (err) {
    console.log("hyperflowAsync error: " + err);
  }
  return { redirectToContinue: false };
}

export async function showMessageCallback(code: string): Promise<void> {
  try {
    let response = await axios.get(
      `${server}/hyperflow/flow/showMessageCallback?code=${code}`
    );

    if (response.status === 200) {
      console.log("ShowMessageCallback success");
      return;
    } else {
      console.log("ShowMessageCallback error status: " + response.status);
    }
  } catch (err) {
    console.log("ShowMessageCallback error: " + err);
    return;
  }
}

export async function getPathSelectConfig(
  configId: string
): Promise<PathSelectConfig | null> {
  try {
    let response = await axios.get<PathSelectConfig>(
      `${server}/hyperflow/flow/getPathSelectConfig/${configId}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function pathSelectCallback(
  callbackData: PathSelectCallbackData
): Promise<void> {
  try {
    let response = await axios.post(
      `${server}/hyperflow/flow/pathSelectCallback`,
      callbackData
    );
    if (response.status === 200) {
      console.log("PathSelectCallback success");
      return;
    } else {
      console.log("PathSelectCallback error status: " + response.status);
    }
  } catch (err) {
    console.log("PathSelectCallback error: " + err);
    return;
  }
}

export async function canContinue(
  code: string
): Promise<CanContinueResponse | null> {
  try {
    let response = await axios.get<CanContinueResponse>(
      `${server}/hyperflow/flow/canContinue?flowCode=${code}`
    );

    if (response.status === 200) {
      console.log("canContinue success");
      return response.data;
    } else {
      console.log("canContinue error status: " + response.status);
      return null;
    }
  } catch (err) {
    console.log("canContinue error: " + err);
    return null;
  }
}

export async function sendOtp(
  execFlowId: string,
  code: string
): Promise<SendOtpResponse | undefined> {
  try {
    let response = await axios.get<SendOtpResponse>(
      `${server}/hyperflow/flow/otp/sendOtp?execFlowId=${execFlowId}&code=${code}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.error(error);
  }
}

export async function verifyOtp(
  execFlowId: string,
  code: string,
  otp: string
): Promise<VerifyOtpResponse> {
  try {
    let req = {
      execFlowId,
      code,
      otp,
    };
    let response = await axios.post<VerifyOtpResponse>(
      `${server}/hyperflow/flow/otp/verifyOtp`,
      req
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return { status: "ERROR" };
    }
  } catch (error) {
    console.error(error);
    return { status: "ERROR" };
  }
}

//MODEL

export interface MultiElecSigResponse {
  confirmed: boolean;
}

export interface CanContinueResponse {
  userAction: string;
  url: string | null;
  message: string | null;
  autoAdvance: boolean | null;
  redirectOnDesktop: boolean | null;
}

export interface BrandConfig {
  backgroundColor1: string;
  backgroundColor2: string;
  logoUrl?: string;
  darkMode: boolean;
  primaryTextColor?: string;
  secondaryTextColor?: string;
  tertiaryTextColor?: string;
  quaternaryTextColor?: string;
  quinaryTextColor?: string;
  senaryTextColor?: string;
  errorTextColor?: string;
  primaryButtonColor?: string;
  secondaryButtonColor?: string;
  tertiaryButtonColor?: string;
  quaternaryButtonColor?: string;
  primaryMessageColor?: string;
  secondaryMessageColor?: string;
  bubbleDescColor?: string;
  barraShow?: boolean;
  backgroundImage?: string;
  xeiaActivado?: boolean;
  xeiaChatActivado?: boolean;
  vigilado?: boolean;
  font: string | null;
  failed?: boolean;
}

export interface ShowMessageConfigModel {
  id: string;
  clientId: string;
  hasConfetti: boolean;
  hasSadFace: boolean;
  isPopup: boolean;
  messages: ShowMessageMessage[];
  continueMessage: string;
}

export interface ShowMessageMessage {
  message: string;
  color: string;
  size: string;
  fontWeight?: string;
  linkUnderlined?: boolean;
  linkColor?: string;
  align?: "CENTER" | "LEFT" | "RIGHT";
  stateIds: string[];
}

export interface CreateAsesorRequest {
  cargo: string;
  direccion: string;
  oficina: string;
  codAsesor: string;
  photoBase64: string;
  registrationId: string;
  code: string;
}

export interface CreateAsesorResponse {
  clientUserId: string;
  url: string;
  code: string;
}

export interface ClientUserData {
  id: string;
  clientId: string;
  name: string;
  email: string;
  userId: string;
  identification: string;
  phoneNumber: string;
  active: boolean;
  esAsesor: boolean;
  asesorData: AsesorData;
}

export interface AsesorData {
  cargo: string;
  direccion: string;
  oficina: string;
  codAsesor: string;
  photoUrl: string;
}

export interface AsesorPicResponse {
  imgBase64: string;
}

export interface StartUserFlowResponse {
  url: string;
}

export interface PathSelectConfig {
  title: string;
  buttons: { [key: string]: string };
}

export interface PathSelectCallbackData {
  code: string;
  execFlowId: string;
  currentStep: string;
  selectedPath: string;
}

export interface VerifyOtpRequest {
  execFlowId: string;
  code: string;
  otp: string;
}

export interface VerifyOtpResponse {
  status: "OK" | "EXPIRED" | "INVALID" | "ERROR" | "MAX_TRIES";
}

export interface SendOtpResponse {
  phone: string;
  expirationMinutes: number;
  block: boolean;
}

export interface HyperFlowCallbackResponse {
  redirectToContinue: boolean;
}
