import { useEffect, useState } from "react";
import { getDrawable } from "../magic_forms_2/api/magic_forms_2_api";
import DrawableFormTemplate from "../magic_forms_2/dtos/DrawableFormTemplate";
import FormPage from "../magic_forms_2/dtos/FormPage";
import useQuery from "../utils/UseQuery";
import "./mfEditor.scss"

const MfEditor = () => {
  const [drawable, setDrawable] = useState<DrawableFormTemplate | undefined>(
    undefined
  );
  const query = useQuery();
  const templateId = query.get("id") ?? "error";

  useEffect(() => {
    //getDrawable
    getDrawable(templateId).then((template) => {
      if (template) {
        console.log(template.name);
        setDrawable(template);
      }
    });
  }, []);

  if (drawable)
    return (
      <div style={{overflowY: "hidden", color: "white"}}>
        <h1>{drawable.name}</h1>
        <div>{getPages(drawable)}</div>
      </div>
    );
  else return <div>Drawable not loaded</div>;

  function getPages(template: DrawableFormTemplate): JSX.Element {
    let lst: Array<JSX.Element> = [];
    let i = 0;
    template.formPages.forEach((page) => {
      i++;
      lst.push(<div className="page" key={i} style={{color: 'white'}}>{getPage(page)}</div>);
    });

    return <div className="page-container">{lst}</div>;
  }

  function getPage(formPage: FormPage): JSX.Element {
    let lst: Array<JSX.Element> = [];
    for (let k in formPage.fields) {
      let f = formPage.fields[k];
      lst.push(
        <div key={k} style={{color: 'white'}} className="field">
          <div>{f.title}</div>
          {/* <div>{f.stitchTypeId}</div>
          <div>{f.type}</div> */}
        </div>
      );
    }
    return (
      <div>
        <div>
          {formPage.number}:{formPage.title}
        </div>
        <div>{lst}</div>
      </div>
    );
  }
};

export default MfEditor;
