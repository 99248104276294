import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useQuery from "../../utils/UseQuery";
import DummiesApi from "../../api/dummies_api";
import more from "./../../assets/more.png";

import "./ins_new.scss";
import {
  TextFieldStyleDummies,
  TextFieldStyleDummies2,
} from "../../utils/TextFieldStyle/TextFieldStyle";
import { ButtonIns, ButtonStyle } from "../../utils/Buttons/Buttons";
import logoIns from "../../assets/logoins.png";
import { successMessage } from "../../api/errorHandler";

function InsCoreidCallback(props) {
  let history = useHistory();
  var [bgColor, setBgColor] = useState(["#008946", "#006b39"]);
  const api = new DummiesApi();
  const query = useQuery();
  const filledFormId = query.get("filledFormId");

  async function nextScreen() {
    var params = window.location.href.split("?")[1];
    history.push("/insFormIntro?" + params);
  }

  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      {/* Main Col */}
      <div className="col" style={{ marginLeft: "10vw", marginRight: "10vw" }}>
        {/* titlesRow */}
        <div className="row" style={{ marginTop: "5vh", marginBottom: "5vh" }}>
          <div style={{ marginBottom: "7vh" }}>
            <img src={logoIns} />
          </div>
          <p className="txt-title">¡Excelente!</p>
          <p className="txt-title">
            Tu identidad ha sido creada satisfactoriamente
          </p>
        </div>

        {/* Button Next */}
        <div className="row btn-more">
          <div className="col">
            <ButtonStyle
              text="Aceptar"
              onClick={() => nextScreen()}
              width="177px"
              height="5vh"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsCoreidCallback;
