import React, { useState } from "react";
import "./InitialForm.scss";
import { TextFieldStyleDummies } from "../../utils/TextFieldStyle/TextFieldStyle";
import NumberFormat from "react-number-format";
import Dropdown from "react-dropdown";
import {
  Checkbox,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  ThemeProvider,
} from "@mui/material";
import { FormGroup, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles({
  select: {
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
  icon: {
    fill: "white",
  },
  root: {
    color: "white",
  },
});

/**
 * types: text, money, multiple
 * @param {*} param0
 * @param {Array} options options
 * @returns
 */
export const GenericField = ({
  label,
  onChange,
  value,
  type,
  disabled = false,
  defaultValue,
  InputProps,
  fieldType,
  title,
  options,
  description,
  supportsOther,
}) => {
  let [otherVal, setOtherVal] = useState("");
  let [multiVal, setMultiVal] = useState("");
  const classes = useStyles();
  // Money filed type
  if (fieldType === "money") {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <TextFieldStyleDummies
          onChange={onChange}
          disabled={disabled}
          label={label}
          value={value}
          type={type}
          defaultValue={defaultValue}
          InputProps={{
            inputComponent: MoneyNumberFormat,
          }}
        />
      </div>
    );
  }
  // Multiple choice field
  else if (fieldType === "multipleChoice") {
    let checks = [];
    options.map((val, i) => {
      let c = (
        <FormControlLabel
          key={i}
          className="form-style"
          control={
            <Checkbox
              className="checkbox-style"
              onChange={(e) => {
                onChange(val, e.target.checked);
              }}
            />
          }
          label={val}
        />
      );
      checks.push(c);
    });
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <FormGroup>{checks}</FormGroup>
      </div>
    );
  }
  // Single choice field
  else if (fieldType === "singleChoice") {
    let a = "";
    let checks = [];
    let values = [];
    options.map((val, i) => {
      values.push(val);
      let c = (
        <FormControlLabel
          key={i}
          className="form-style"
          value={val}
          control={<Radio className="checkbox-style" />}
          label={val}
        />
      );
      checks.push(c);
    });
    let c = <div></div>;
    if (supportsOther) {
      values.push("otro");
      c = (
        <div key={"otro"} style={{ marginTop: "3vh" }}>
          <TextFieldStyleDummies
            onChange={(e) => {
              let val = e.target.value;
              setOtherVal(val);
              setMultiVal("");
              onChange(`:otro:${val}`, e.target.value !== "");
              values.forEach((val2) => {
                onChange(val, false);
              });
            }}
            disabled={disabled}
            label={"Otro"}
            value={otherVal}
          />
        </div>
      );
    }
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <FormGroup>
          <RadioGroup
            onChange={(e) => {
              let selected = e.target.value;
              setMultiVal(selected);
              values.forEach((val) => {
                onChange(val, selected === val);
              });
              if (supportsOther) {
                onChange(":otro:", false);
                setOtherVal("");
                // c.value = "";
              }
            }}
            value={multiVal}
          >
            {checks}
          </RadioGroup>
        </FormGroup>
        {c}
      </div>
    );
  }
  // Dropdown field
  else if (fieldType === "dropdown") {
    let a = "";
    let items = [];
    options.map((val, i) => {
      let c = (
        <MenuItem key={i} value={val}>
          {val}
        </MenuItem>
      );
      items.push(c);
    });
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <FormGroup
          sx={{
            color: "white",
            borderColor: "white",
            outlineColor: "white",
            accentColor: "white",
          }}
        >
          <Select
            className={classes.select}
            sx={{
              color: "white",
              borderColor: "white",
              outlineColor: "white",
              accentColor: "white",
            }}
            value={multiVal}
            inputProps={{
              classes: {
                icon: classes.icon,
                root: classes.root,
              },
            }}
            onChange={(e) => {
              setMultiVal(e.target.value);
              onChange(e);
            }}
          >
            {items}
          </Select>
        </FormGroup>
      </div>
    );
  } else if (fieldType === "file") {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <input type="file" onChange={onChange} />
      </div>
    );
  }
  // Number field
  else if (fieldType === "number") {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <TextFieldStyleDummies
          onChange={onChange}
          disabled={disabled}
          label={label}
          value={value}
          type={"number"}
          defaultValue={defaultValue}
        />
      </div>
    );
  }
  // Label Field
  else if (fieldType === "label" || fieldType === "labelSummary") {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {title}
        </p>
        <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {description}
        </p>
      </div>
    );
  }

  // Default field
  else {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <TextFieldStyleDummies
          onChange={onChange}
          disabled={disabled}
          label={label}
          value={value}
          type={type}
          defaultValue={defaultValue}
          InputProps={InputProps}
        />
      </div>
    );
  }
};

function MoneyNumberFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"$"}
      // isNumericString
    />
  );
}
