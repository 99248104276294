import React, { useState, useEffect } from "react";
import "./ButtonsComp.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Button1, Button2, Button3 } from "../../utils/Buttons/Buttons";
function ButtonsComp() {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 600px)").matches
  );
  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia("(min-width: 600px)").addListener(handler);
  }, []);

  return (
    <div className="buttonscomp">
      <div className="mini-nav">
        <p>Buttons</p>
      </div>
      <div>
        <Box sx={{ flexGrow: 2 }} className="container-box">
          {/* ----------------INPUT1---------------------- */}
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <div>
                <p className="title-b">Primary</p>
                <div className="btn-enter">
                  <p>Enable</p>

                  {matches ? (
                    <Button
                      text="Button"
                      // onClick={() => Next()}
                      width={matches ? "177px" : "77px"}
                      height="5vh"
                    />
                  ) : (
                    <Button2
                      text="Button"
                      // onClick={() => Next()}
                      width={matches ? "177px" : "77px"}
                      height="5vh"
                    />
                  )}
                </div>
              </div>

              <div>
                {" "}
                <p>Disable</p>
                <div className="btn-enter">
                  {matches ? (
                    <Button
                      text="Button"
                      // onClick={() => Next()}
                      width={matches ? "177px" : "77px"}
                      height="5vh"
                    />
                  ) : (
                    <Button2
                      text="Button"
                      // onClick={() => Next()}
                      width={matches ? "177px" : "77px"}
                      height="5vh"
                    />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <p className="title-b">Secondary</p>
                <div className="btn-enter">
                  <p>Enable</p>
                  {matches ? (
                    <Button1
                      text="RE-ENVIAR CORREO"
                      // onClick={() => Next()}
                      width={matches ? "177px" : "77px"}
                      height="5vh"
                    />
                  ) : (
                    <Button3
                      text="RE-ENVIAR CORREO"
                      // onClick={() => Next()}
                      width={matches ? "177px" : "77px"}
                      height="5vh"
                    />
                  )}
                </div>
              </div>

              <div>
                {" "}
                <div className="btn-enter">
                  <p>Disable</p>

                  {matches ? (
                    <Button1
                      text="RE-ENVIAR CORREO"
                      // onClick={() => Next()}
                      width={matches ? "177px" : "77px"}
                      height="5vh"
                    />
                  ) : (
                    <Button3
                      text="RE-ENVIAR CORREO"
                      // onClick={() => Next()}
                      width={matches ? "177px" : "77px"}
                      height="5vh"
                    />
                  )}
                </div>
              </div>
            </Grid>

            <Grid item xs={3}>
              <div>
                <p className="titleLink">Link</p>
                <div className="btn-enter">
                  <p>Enable</p>
                  <a href="https://google.com" style={{ color: "white" }}>
                    Button
                  </a>
                </div>
              </div>

              <div>
                <p>Focus</p>
                <div className="btn-enter">
                  <p>Enable</p>
                  <a href="https://google.com" style={{ color: "gray" }}>
                    Button
                  </a>
                </div>
              </div>
            </Grid>

            <Grid item xs={3}>
              <div>
                <p>Tabs</p>
                <div className="btn-enter">
                  <Button3
                    text="Button"
                    // onClick={() => Next()}
                    width="77px"
                    height="5vh"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
export default ButtonsComp;
