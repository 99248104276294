import React,{useState} from "react";
import "./LVerifiquemosCuentaAhorros.scss";
import {TextFieldStyleSS} from "../../utils/TextFieldStyle/TextFieldStyle";
import { Button, ButtonStyle } from "../../utils/Buttons/Buttons";
import {useHistory} from 'react-router-dom'

function LVerifiquemosCuentaAhorros(props) {
 const history = useHistory();
  
  const Next= () => {
    props.redirect();
  };
 
  const userChange = (name, value) => {
    var temp=props.data;
    temp[name]=value;
    props.setData(temp);
};

  return (
    <div className="enterdata-style">
      <p>
      Verifiquemos tus datos
      </p>
    
      <div style={{ marginTop: "3em" }}>
        <TextFieldStyleSS label="Nombre y Apellido" value={props.data["nombre"]} onChange={(e)=>{userChange("nombre",e.target.value)}}  />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="Dirección" value={props.data["direccion"]} onChange={(e)=>{userChange("direccion",e.target.value)}} />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="Correo" value={props.data["correo"]} onChange={(e)=>{userChange("correo",e.target.value)}} />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="Monto de ingresos" value={props.data["monto"]} onChange={(e)=>{userChange("monto",e.target.value)}} />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="Tipo de ingresos" value={props.data["tipoingresos"]} onChange={(e)=>{userChange("tipoingresos",e.target.value)}} />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="Tipo de plan" value={props.data["tipo"]} onChange={(e)=>{userChange("tipo",e.target.value)}} />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="País y ciudad de residencia" value={props.data["pais"]} onChange={(e)=>{userChange("pais",e.target.value)}} />
      </div>
      <div className="btn-enter">
        <ButtonStyle
          text="ENVIAR Y FIRMAR"
          onClick={() => Next()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default LVerifiquemosCuentaAhorros;
