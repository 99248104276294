import React,{useState} from "react";
import "./RIngresaAfiliacion.scss";
import {TextFieldStyleSS} from "../../utils/TextFieldStyle/TextFieldStyle";
import { Button, ButtonStyle } from "../../utils/Buttons/Buttons";
import {useHistory} from 'react-router-dom'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function RIngresaAfiliacion(props) {
 const history = useHistory();
  const Next= () => {
    
    props.redirect();
  };
 
  const userChange = (name, value) => {
   // var temp=props.data;
    //temp[name]=value;
   //  props.setData(temp);
};

  return (
    <div className="enterdata-style">
      <p>
      Diligencie los siguientes <br/> datos
      </p>
     
       
  
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="Estado Civil" onChange={(e)=>{userChange("estado",e.target.value)}} />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="Actividad Económica" onChange={(e)=>{userChange("actividad",e.target.value)}} />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="Ingresos mensuales" onChange={(e)=>{userChange("ingresos",e.target.value)}} />
      </div>
      <div className="btn-enter">
        <ButtonStyle
          text="ENVIAR Y FIRMAR"
          onClick={() => Next()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default RIngresaAfiliacion;
