import React from "react";
import "./FormData2.scss";
import { Row, Col } from "react-bootstrap";
import { Button } from "./../../utils/Buttons/Buttons";
import lock from "./../../assets/lock.png";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { propTypes } from "react-bootstrap/esm/Image";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "center",
  },
}));
function FormData2(props) {
  const classes = useStyles();
  return (
    <div className="frm2">
      {/* ---------------------TABLE 1----------------------------- */}
      <p className="title">DATOS DEL PROPIETARIO</p>

      <div className={classes.root}>
        <div className="div-container">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div>
                <p>PRIMER APELLIDO</p>
                <p>SEGUNDO APELLIDO</p>
                <p>NOMBRE</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <p>RUHL</p>
              <p>CHAPARRO</p>
              <p>ERICK</p>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* ---------------------TABLE 2----------------------------- */}

      <div className={classes.root}>
        <div className="div-container">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div>
                <p>DOCUMENTO</p>
                <p>No. DOCUMENTO</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <p>CÉDULA</p>
              <p>79798417</p>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* ---------------------TABLE 3----------------------------- */}

      <div className={classes.root}>
        <div className="div-container">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div>
                <p>DIRECCIÓN</p>
                <p>CIUDAD</p>
                <p>TELÉFONO</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <p>DG 40 A # 8 -91</p>
              <p>BOGOTÁ D.C.</p>
              <p>304 4155101</p>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="sign">
        <p className="sign-pro">FIRMA DEL PROPIETARIO</p>
        <Row>
          <Col>
            <p>
              Nombre(s): ERICK Apellido(s): RUHL CHAPARRO <br></br>
              Identificación No.: 79798417
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Fecha de firma: 2021-11-10 08:30 UTC-5</p>
          </Col>
          <Col>
            <img src={lock} alt="icon" />
          </Col>
        </Row>
      </div>

      {/* ---------------------TABLE 4----------------------------- */}
      <div style={{ marginTop: "2em" }}>
        <p className="title">DATOS DEL COMPRADOR</p>

        <div className={classes.root}>
          <div className="div-container">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div>
                  <p>PRIMER APELLIDO</p>
                  <p>SEGUNDO APELLIDO</p>
                  <p>NOMBRE</p>
                </div>
              </Grid>
              <Grid item xs={6}>
                <p>FORERO</p>
                <p>CAMACHO</p>
                <p>LAURA ISABELLA</p>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={classes.root}>
          <div className="div-container">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div>
                  <p>CÉDULA</p>
                  <p>No. DOCUMENTO</p>
                </div>
              </Grid>
              <Grid item xs={6}>
                <p>CÉDULA</p>
                <p>1013692639</p>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={classes.root}>
          <div className="div-container">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div>
                  <p>DIRECCIÓN</p>
                  <p>CIUDAD</p>
                  <p>TELÉFONO</p>
                </div>
              </Grid>
              <Grid item xs={6}>
                <p>CALLE 1 F BIS # 19 - 76</p>
                <p>BOGOTÁ D.C.</p>
                <p>3022513712</p>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="sign">
          <p className="sign-pro">FIRMA DEL COMPRADOR</p>
          <Row>
            <Col>
              <p>
                Nombre(s): LAURA ISABELLA Apellido(s): FORERO CAMACHO <br></br>{" "}
                Identificación No.: 1013692639
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Fecha de firma: 2021-11-10 08:30 UTC-5</p>
            </Col>
            <Col>
              <img src={lock} alt="icon" />
            </Col>
          </Row>
        </div>
      </div>
      {/* ---------------------------BUTTON-NEXT---------------------------------------- */}
      <div style={{ marginTop: "2em" }}>
        <div onClick={() => props.UpdateData(false)}>
          <Button
            text="SIGUIENTE"
            onClick={() => props.next()}
            width="177px"
            height="5vh"
          />
        </div>
      </div>
    </div>
  );
}
export default FormData2;
