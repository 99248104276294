import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DummiesApi from "../../api/dummies_api";
import { Button, Button3 } from "../../utils/Buttons/Buttons";
import { TextFieldStyle } from "../../utils/TextFieldStyle/TextFieldStyle";
import { TextField1 } from "../InputT/TextField1/TextField1";
import { infoMessage, successMessage } from "../../api/errorHandler";
import useQuery from "../../utils/UseQuery";
import "./InitialForm.scss";
import { GenericField } from "./GenericField";
import { useHistory } from "react-router-dom";

function ElectronicSignatureRedirect(props) {
  const query = useQuery();
  const id = query.get("filledFormId");
  console.log(id);
  const regId = query.get("registrationId");
  console.log("regId: " + regId);
  const api = new DummiesApi();
  var [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);

  let history = useHistory();

  async function getRedirection() {
    var resp = await api.getElectronicSigRedirect(id);
    console.log(resp);
    window.location.href = resp.url;
  }

  useEffect(() => {
    console.log("loading data... for id: " + id);
    api.getFormByFilledFormId(id).then((res) => {
      console.log(res);
      let newColor = [`#${res.firstColorHex}`, `#${res.secondColorHex}`];
      console.log(newColor);
      setBgColor(newColor);
      getRedirection();
    });
  }, []);

  //Loading
  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        className="col"
        style={{ paddingTop: "10%", marginLeft: "10vw", marginRight: "10vw" }}
      >
        <h1 className="txt-label">Espera un momento...</h1>
      </div>
      <div style={{ marginTop: "5%", marginBottom: "30%" }}></div>
    </div>
  );
}

export default ElectronicSignatureRedirect;
