import React, { useEffect, useState } from "react";
import useQuery from "../../utils/UseQuery";
import { getColorFromCode } from "../api/magic_forms_2_api";
import { ReactComponent as Salvavidas } from "../../assets/salvavidas.svg";
import { ReactComponent as Pausa } from "../../assets/Pausa.svg";
import {
  selectErrorMessage,
  tryGetByCode,
} from "../formComponent/formComponentSlicer";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

export const Explanation = () => {
  const query = useQuery();
  const [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  const code = query.get("code") ?? "error";
  const pauseCode = query.get("pauseCode") ?? "";

  let erroMessage = useAppSelector(selectErrorMessage);

  const [_, setCookie] = useCookies(["pauseAdvice"]);
  let dispatch = useAppDispatch();
  const [step, setStep] = useState(1);
  const history = useHistory();

  async function getColors() {
    let resp = await getColorFromCode(code);
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
  }

  function getFormAndStuff() {
    dispatch(tryGetByCode(code));
  }

  const handleContinuar = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (step === 1) {
      setStep(step + 1);
    }
    if (step > 1) {
      setCookie("pauseAdvice", "Done", { path: "/" });
      let searchParams = `?code=${code}`;
      if (pauseCode && pauseCode !== "") {
        searchParams = searchParams + `&pauseCode=${pauseCode}`;
      }
      history.replace({ pathname: "magicForms2", search: searchParams });
    }
  };

  useEffect(() => {
    getColors();
    getFormAndStuff();
  }, []);

  const contentByStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <h3
              style={{
                width: "60%",
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "center",
                color: "white",
                fontFamily: "'Open Sans', sans-serif",
              }}
            >
              Pausa tu proceso en cualquier momento
            </h3>

            <div
              style={{
                width: "220px",
                height: "220px",
                background: "#FFFFFF",
                borderRadius: "214.5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="https://cdn.anteia.co/images/readingBook.png"
                alt="Caricatura de persona leyendo un libro"
                height={"120px"}
                width={"120px"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: "75%",
                  textAlign: "start",
                  fontSize: "20px",
                  fontWeight: "initial",
                  fontFamily: "'Open Sans'",
                  color: "white",
                }}
              >
                Sabemos cuan importante es tu tiempo, si en algún momento deseas
                o necesitas pausar el proceso podrás hacerlo de manera muy
                sencilla
              </p>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h3
              style={{
                width: "60%",
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "center",
                color: "white",
                fontFamily: "'Open Sans', sans-serif",
              }}
            >
              ¿Cómo pausar tu proceso
            </h3>
            <p
              style={{
                width: "75%",
                textAlign: "start",
                fontSize: "20px",
                fontWeight: "initial",
                fontFamily: "'Open Sans'",
                color: "white",
              }}
            >
              En la parte superior derecha veras un icono de un LiveSaver como
              este
            </p>
            <Salvavidas />
            <p
              style={{
                width: "75%",
                textAlign: "start",
                fontSize: "20px",
                fontWeight: "initial",
                fontFamily: "'Open Sans'",
                color: "white",
              }}
            >
              Sólo debes dar clic y se desplegará la función de:
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  textAlign: "start",
                  fontSize: "20px",
                  fontWeight: "initial",
                  fontFamily: "'Open Sans'",
                  color: "white",
                  marginRight: "13px",
                }}
              >
                Pausar proceso
              </p>
              <Pausa />
            </div>
            <p
              style={{
                width: "75%",
                textAlign: "start",
                fontSize: "20px",
                fontWeight: "initial",
                fontFamily: "'Open Sans'",
                color: "white",
              }}
            >
              Das click y el proceso se pausará, te enviaremos un mensaje a la
              cuenta de correo electrónico con la que te registraste, recibirás
              un enlace para retomar tu proceso en cualquier momento.
            </p>
          </>
        );

      default:
        return <></>;
    }
  };
  return (
    <div
      style={{
        background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        overflow: "auto",
      }}
    >
      {contentByStep()}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "15px",
            width: "15px",
            marginRight: "20px",
            background: step === 1 ? "white" : "transparent",
            borderRadius: "9999px",
            border: "1px solid #FFFFFF ",
          }}
        ></div>
        <div
          style={{
            height: "15px",
            width: "15px",
            border: "1px solid #FFFFFF ",
            background: step === 1 ? "transparent" : "white",
            borderRadius: "9999px",
          }}
        ></div>
      </div>
      <button
        onClick={handleContinuar}
        style={{
          padding: "14px 44px 20px 44px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
          fontWeight: "bold",
          fontFamily: "'Open Sans'",
          color: "white",
          background: "rgba(0,0,0,0.5)",
          borderRadius: "30px",
          border: "none",
          textTransform: "uppercase",
          cursor: "pointer",
          overflow: "auto",
        }}
      >
        Continuar
      </button>
    </div>
  );
};
