import { useEffect, useRef, useState } from "react";
import StyleProperties from "../../dtos/StyleProperties";

export interface AutocompleteSelectProps {
  title: string;
  otherDdAct?: boolean;
  options: { label: string }[];
  selected: { label: string };
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: any) => void;
  onOtherChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  otherValue?: string;
  style?: StyleProperties;
  darkMode?: boolean;
  align?: "CENTER" | "LEFT" | "RIGHT";
  disabled?: boolean;
}

export const AutoCompleteInput: React.FC<AutocompleteSelectProps> = ({
  title,
  otherDdAct,
  options,
  selected,
  onChange,
  onOtherChange,
  otherValue,
  style,
  darkMode,
  align,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const getColor = () => {
    if (style?.color) {
      return style.color;
    } else {
      return darkMode ? "white" : "black";
    }
  };

  const getBackgroundColor = () => {
    if (style?.modalBackgroundColor) {
      return style.modalBackgroundColor;
    } else if (style && style.color) {
      return style.color;
    } else {
      return darkMode ? "black" : "white";
    }
  };

  const getDefaultMargin = () => {
    if (style && (style.margin || style.marginTop || style.marginBottom)) {
      return {
        margin: style.margin,
        marginTop: style.marginTop,
        marginBottom: style.marginBottom,
      };
    } else {
      return { margin: "15px 0" };
    }
  };

  const getBorderColor = () => {
    if (style && style.borderColor) {
      return style.borderColor;
    } else {
      return darkMode ? "white" : "black";
    }
  };

  const getSelectedColor = (value: { label: string }) => {
    if (value.label === selected.label) {
      if (style && style.selectedColor) {
        return style.selectedColor;
      } else {
        return darkMode ? "white" : "black";
      }
    } else {
      if (style && style.unselectedColor) {
        return style.unselectedColor;
      } else {
        return darkMode ? "black" : "white";
      }
    }
  };
  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setIsInputOpen(true);
    setInputValue(value);
    setIsOpen(true);
    resizeTextarea();
  };

  const handleOptionClick = (e: any, option: { label: string }) => {
    onChange(e, option);
    setInputValue(option.label);
    setIsOpen(false);
    resizeTextarea();
  };

  useEffect(() => {
    resizeTextarea();
  }, [inputValue]);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleInputOpen = () => {};

  const optionsSorted = options.sort((a, b) => a.label.localeCompare(b.label));
  // order options by label alphabetically
  filteredOptions.sort((a, b) => a.label.localeCompare(b.label));
  //Helper close functions
  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const close = async () => {
    console.log("sleep");
    await sleep(150);
    setIsOpen(false);
    setIsInputOpen(false);
  };

  //Height control
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  //Scroll control
  const handleDropdownOpen = () => {
    console.log("scroll")
    const container = window.document.getElementById("scroller2");
    const dropdown = textAreaRef.current;

    if (container && dropdown) {
      const containerRect = container.getBoundingClientRect();
      const dropdownRect = dropdown.getBoundingClientRect();

      if (dropdownRect.bottom > containerRect.bottom) {
        container.scrollTop += dropdownRect.bottom - containerRect.bottom;
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleDropdownOpen();
    }
  }, [isOpen]);

  const resizeTextarea = () => {
    if (!textAreaRef?.current) return;
    // Set height temporarily to 0 to get intrinsic content height
    textAreaRef.current.style.height = "0px";
    const height = textAreaRef.current.scrollHeight + "px";
    textAreaRef.current.style.height = height;
  };

  return (
    <div
      className={`relative w-full flex flex-col `}
      style={getDefaultMargin()}
    >
      <textarea
        disabled={disabled}
        ref={textAreaRef}
        // type='text'
        value={inputValue}
        onChange={handleInputChange}
        // onBlur={() => close()}
        placeholder={title}
        style={{
          ...style,
          padding: "0",
          margin: "0",
          paddingRight: "40px",
          textAlign: "center",
          color: getColor(),
          minHeight: "38px",
          borderColor: getBorderColor(),
        }}
        className={`bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center w-full mh-[38px] place-content-center placeholder-black`}
      />
      <div
        className='absolute right-3 top-2 cursor-pointer flex justify-center items-center'
        onClick={() => {
          setIsInputOpen(false);
          setIsOpen(!isOpen);
        }}
      >
        <div
          className='border-[1px] h-[20px] mr-3'
          style={{ borderColor: getColor() }}
        ></div>
        <svg
          width='20'
          height='16'
          viewBox='0 0 20 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1.90735e-06 3.33882C0.00308228 2.80387 0.116661 2.27562 0.333303 1.78865C0.560415 1.26198 0.931032 0.813264 1.4009 0.496092C1.87076 0.17892 2.42001 0.00669988 2.98305 0L17.015 0C17.578 0.00669988 18.1273 0.17892 18.5972 0.496092C19.067 0.813264 19.4376 1.26198 19.6648 1.78865C19.9448 2.39465 20.0526 3.06888 19.976 3.73449C19.8994 4.4001 19.6414 5.03032 19.2315 5.55335L12.2155 14.2411C11.9501 14.5818 11.6131 14.8569 11.2293 15.046C10.8456 15.2351 10.425 15.3333 9.99903 15.3333C9.57302 15.3333 9.15249 15.2351 8.76874 15.046C8.385 14.8569 8.04791 14.5818 7.78258 14.2411L0.766592 5.55335C0.269764 4.92711 -0.000940323 4.1451 1.90735e-06 3.33882Z'
            fill={getColor()}
          />
        </svg>
      </div>
      {isOpen && (
        <div
          className='fixed top-0 left-0 w-screen h-screen bg-black opacity-0 z-[100]'
          onClick={() => {
            setIsOpen(false);
          }}
        ></div>
      )}
      {isOpen && (
        <ul
          className='absolute  w-full border-[1px] rounded-xl shadow-lg text-start max-h-[200px] overflow-auto'
          style={{
            ...style,
            borderColor: getBorderColor(),
            backgroundColor: getBackgroundColor(),
            zIndex: 1000,
            margin: "40px 0 0 0",
          }}
        >
          {isInputOpen &&
            filteredOptions.map((option, index) => {
              return (
                <li
                  key={index}
                  style={{ color: getSelectedColor(option) }}
                  onClick={(e) => handleOptionClick(e, option)}
                  className='px-4 py-2 cursor-pointer'
                >
                  {option.label}
                </li>
              );
            })}
          {!isInputOpen &&
            optionsSorted.map((option, index) => {
              return (
                <li
                  key={index}
                  style={{ color: getSelectedColor(option) }}
                  onClick={(e) => handleOptionClick(e, option)}
                  className='px-4 py-2 cursor-pointer'
                >
                  {option.label}
                </li>
              );
            })}
        </ul>
      )}
      {otherDdAct && (
        <input
          placeholder={"¿Cual?"}
          style={{
            ...style,
            padding: "0",
            color: getColor(),
            borderColor: getBorderColor(),
            margin: "15px 0",
          }}
          className='bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center w-full h-[38px]'
          type='text'
          value={otherValue}
          disabled={disabled}
          onChange={(ev) => {
            if (onOtherChange) {
              onOtherChange(ev);
            }
          }}
        />
      )}
    </div>
  );
};
