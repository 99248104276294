import React, { useEffect, useRef, useState } from "react";
import FormField from "../../dtos/FormField";
import { GenericField2 } from "../GenericField2.js";
import { ButtonComponent } from "./ButtonComponent";

export interface SliderProps {
  slides: FormField[];
  darkMode?: boolean;
  backButtonText: string;
  backButtonColor: string;
  backOnClick: () => void;
  continueButtonText: string;
  continueButtonColor: string;
  continueOnClick: () => void;
  color?: string;
}

export const SliderComponent = (props: SliderProps) => {
  const [startX, setStartX] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [translateX, setTranslateX] = useState<number>(0);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleTouchStart = (e: TouchEvent) => {
    setStartX(e.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (!isDragging) return;

    const deltaX = e.touches[0].clientX - startX;
    setTranslateX(deltaX);
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;

    setIsDragging(false);

    const threshold = 100;

    if (translateX > threshold && currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    } else if (
      translateX < -threshold &&
      currentSlide < props.slides.length - 1
    ) {
      setCurrentSlide((prev) => prev + 1);
    }

    setTranslateX(0);
  };

  useEffect(() => {
    const slider = sliderRef.current;

    if (slider) {
      slider.addEventListener("touchstart", handleTouchStart);
      slider.addEventListener("touchmove", handleTouchMove);
      slider.addEventListener("touchend", handleTouchEnd);

      return () => {
        slider.removeEventListener("touchstart", handleTouchStart);
        slider.removeEventListener("touchmove", handleTouchMove);
        slider.removeEventListener("touchend", handleTouchEnd);
      };
    }
  }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

  return (
    <div className=' h-[84%] w-full flex flex-col justify-normal overflow-hidden'>
      <div className='h-[90%]'>
        <div
          className='flex transition-transform duration-300 ease-in-out h-[84%]'
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          ref={sliderRef}
        >
          {props.slides.map((slide, index) => (
            <div key={index} className='w-full flex-shrink-0'>
              <div className='flex flex-col h-full justify-center'>
                <div className='mb-16'>
                  <h2
                    style={{ color: props.color }}
                    className='font-black text-[24px] lg:text-[28px] text-center'
                  >
                    {slide.title}
                  </h2>
                </div>
                <div className='mb-8'>
                  {
                    <GenericField2
                      style={slide.style}
                      align={slide.align}
                      title={slide.title}
                      fieldType={slide.type}
                      description={slide.description}
                      options={[]}
                      supportsOther={false}
                      disabled={false}
                      darkMode={props.darkMode}
                      subFields={slide.subFields}
                    />
                  }
                </div>
              </div>
            </div>
          ))}
        </div>

        <div
          className='flex space-x-3 justify-center w-full lg:max-w-[50vw]'
          style={{
            position: "absolute",
            bottom: "20%",
            // right: "20%",
          }}
        >
          {props.slides.map((_, index) => (
            <div
              onClick={() => setCurrentSlide(index)}
              style={{
                borderColor: props.color ? props.color : "white",
                backgroundColor:
                  currentSlide === index ? props.color : "transparent",
              }}
              key={index}
              className={`w-[15px] h-[15px] rounded-full border-[1px] border-solid transition-colors duration-300 ease-in-out cursor-pointer`}
            ></div>
          ))}
        </div>
      </div>
      <div
        className='w-[100%] flex flex-row justify-evenly items-start h-[13%]'
        style={{
          // position: "absolute",
          // bottom: "10%",
          // right: "20%",
        }}
      >
        {" "}
        <ButtonComponent
          backgroundColor={props.backButtonColor}
          color={"white"}
          onClick={() => {
            if (currentSlide === 0) {
              props.backOnClick();
            } else {
              setCurrentSlide((prev) => prev - 1);
            }
          }}
          text={currentSlide > 0 ? "ANTERIOR" : props.backButtonText}
        />{" "}
        <ButtonComponent
          onClick={() => {
            if (currentSlide === props.slides.length - 1) {
              props.continueOnClick();
            } else {
              setCurrentSlide((prev) => prev + 1);
            }
          }}
          text={props.continueButtonText}
          backgroundColor={props.continueButtonColor}
          color={"white"}
        />
      </div>
    </div>
  );
};
