import React, { useState } from "react";
import { TextTransform } from "./components/TextTransform";
import { ButtonComponent } from "./components/ButtonComponent";

export interface CancelOptionsModalProps {
  exitText: string;
  optionList: Array<string>;
  onSelect: (arg: string) => void;
  bgColor: string;
  textColor: string;
  onClose: () => void;
}

export const CancelOptionsModal: React.FC<CancelOptionsModalProps> = ({
  exitText,
  optionList,
  onSelect,
  bgColor,
  textColor,
  onClose,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [otherOption, setOtherOption] = useState("");

  const getSelectedColor = (value: string) => {
    return textColor;
    // if (value === selectedOption) {
    //   if (style && style.selectedColor) {
    //     return style.selectedColor;
    //   } else {
    //     return darkMode ? "white" : "black";
    //   }
    // } else {
    //   if (style && style.unselectedColor) {
    //     return style.unselectedColor;
    //   } else {
    //     return darkMode ? "black" : "white";
    //   }
    // }
  };
  const getBorderColor = () => {
    return textColor;
  };

  return (
    <div
      // onClick={onClose}
      className='p-10 absolute h-[100vh] w-[100vw] bg-black bg-opacity-50 flex felx-col justify-center items-center z-[1000]'
    >
      <div
        className='flex flex-col justify-center items-center  w-[90vw] lg:w-[50vw] p-10 rounded-lg z-[1002]'
        style={{ backgroundColor: bgColor }}
      >
        <div
          className='flex flex-row justify-center'
          style={{ color: textColor }}
        >
          {exitText}
        </div>
        {optionList.map((item, i) => {
          return (
            <div
              className='flex flex-row justify-between w-full'
              key={`${i}${item}`}
            >
              <input
                type='radio'
                value={item}
                id={`${i}${item}`}
                name={`${i}${item}`}
                onClick={() => {
                  setSelectedOption(item);
                  setOtherOption("");
                }}
                checked={selectedOption === item}
                onChange={(ev) => {}}
              ></input>
              <label
                className='inline-block relative py-2 px-6 cursor-pointer select-none text-start'
                htmlFor={`${i}${item}`}

              >
                <span
                  style={{
                    backgroundColor:
                      selectedOption === item
                        ? getSelectedColor(item)
                        : "transparent",
                    borderColor: getBorderColor(),
                    textAlign: "start",
                  }}
                  className={`inline-block absolute left-0 top-1/2 transform -translate-y-1/2 w-[16px] h-[16px] border-2  rounded-full  transition `}
                ></span>
                <span>
                  <TextTransform
                    text={item}
                    style={{
                      color: textColor,
                      textAlign: "end",
                      width: "100%",
                    }}
                  />
                </span>
              </label>
            </div>
          );
        })}
        <div className="mt-[10px]" style={{ color: textColor }}>Otra razón:</div>
        <input
          style={{ color: textColor, borderColor: textColor }}
          className='mb-6 bg-transparent border-[1px] rounded-[8px] text-center justify-center items-center w-[90%] lg:w-[60%] h-[38px]'
          type='text'
          value={otherOption}
          onChange={(ev) => {
            setOtherOption(ev.target.value);
            setSelectedOption(ev.target.value);
          }}
        />
        <ButtonComponent
          backgroundColor={textColor}
          color={"white"}
          onClick={() => {
            onSelect(selectedOption);
          }}
          text='Enviar'
        />
        <div className='mb-20px h-[20px]'></div>
        <ButtonComponent
          backgroundColor={"gray"}
          color={"white"}
          onClick={() => {
            onClose();
          }}
          text='Volver'
        />
      </div>
    </div>
  );
};
