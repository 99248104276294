import React, { useState, useEffect } from "react";
import "./MultipleChoice.scss";
import { TextField3 , TextField4} from "./../MultipleChoice/TextField3/TextField3";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
function MultipleChoice() {
  const [err, setErr] = useState(true);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 600px)").matches
  );
  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia("(min-width: 600px)").addListener(handler);
  }, []);
  return (
    <div className="choice">
      <div className="mini-nav">
        <p>Question Multiple Choice</p>
      </div>
      <Box sx={{ flexGrow: 2 }} className="container-box">
        {/* ----------------INPUT1---------------------- */}
        <Grid container spacing={1}>
          <Grid item xs={matches ? 3.5 : 6}>
            <div>
              <p>Inactive</p>
              <TextField3 />
            </div>

            <div>
              {" "}
              <p>Focus</p>
              <TextField3 />
            </div>
          </Grid>
          {/* ----------------INPUT2---------------------- */}

          <Grid item xs={matches ? 3.5 : 6}>
            <div>
              <p>Active</p>
              <TextField3 />
            </div>
            <div>
              <p>Error</p>
              <TextField3 err={err} setErr={setErr} />
            </div>
          </Grid>
          {/* ----------------INPUT3---------------------- */}
          <div className="last-col"></div>

          <Grid item xs={matches ? 3.5 : 6} className="last-col-div">
            <p>Layout</p>
            <div>
              <TextField3 />
            </div>
            <div style={{ marginTop: "1em" }}>
              <TextField3 />
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 2 }} className="container-box">
        {/* ----------------INPUT1---------------------- */}
        <Grid container spacing={1}>
          <Grid item xs={matches ? 3.5 : 4}>
            <div>
              <p>Inactive</p>
              <TextField4 />
            </div>

            <div>
              {" "}
              <p>Focus</p>
              <TextField4 />
            </div>
          </Grid>
          {/* ----------------INPUT2---------------------- */}

          <Grid item xs={matches ? 3.5 : 4}>
            <div>
              <p>Active</p>
              <TextField4 />
            </div>
            <div>
              <p>Error</p>
              <TextField4 err={err} setErr={setErr} />
            </div>
          </Grid>
          {/* ----------------INPUT3---------------------- */}
          <div className="last-col"></div>

          <Grid item xs={matches ? 3.5 : 4} className="last-col-div">
            <p>Layout</p>
            <div>
              <TextField4/>
            </div>
            <div style={{ marginTop: "1em" }}>
              <TextField4 />
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
export default MultipleChoice;
