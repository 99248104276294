import React, { useState, useEffect } from "react";
import "./VideoMock.scss";
import { useHistory } from "react-router-dom";
import ScreenSuccess from "../ScreenSuccess/ScreenSuccess";


function VideoMock(props) {
  const history = useHistory();
  
  const [second, setSecond] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => {
      setSecond(second <= 10 ? second + 1 : second);
      
    }, 1000);
    return () => clearInterval(timer);
  });
  console.log(second, "SECOND VIDEO");

  return (
    <div className="video">
      {second === 11 && <ScreenSuccess tomaroute={props.tomaroute} />}

      {second <= 10  && (
        <div className="video-start">
         {<video width="100%" autoplay="autoplay" >
  <source src={props.video} type="video/mp4"></source>
</video> }
        </div>
      )}
    </div>
  );
}

export default VideoMock;
