import React from "react";
import "./PaletteColorFul.scss";
import img from "./../../assets/logoanteia.png";
function PaletteColorFul() {
  return (
    <div className="palette">
      <div className="mini-nav">
        <p>Palette - Colorful</p>
      </div>
      <img src={img} alt="logo" />
      <div className="picker-color">
        <div className="color1"></div>
        <div className="color2"></div>
        <div className="color3"></div>
        <div className="color4"></div>
      </div>
    </div>
  );
}
export default PaletteColorFul;
