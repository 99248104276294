import "./identidadDigitalUsuarioDummy.scss";

import { ReactComponent as UPLOAD } from "../../../assets/Upload.svg";
import { ReactComponent as LEFT } from "../../../assets/Polygon 7.svg";
import { ReactComponent as RIGHT } from "../../../assets/Polygon 8.svg";
import ateneaGrande from "../../../assets/ateneaGrande.png";
import ros011 from "../../../assets/ros01 1.png";
import ros021 from "../../../assets/ros02 1.png";
import ros031 from "../../../assets/ros03 1.png";
import ros032 from "../../../assets/ros03 2.png";
import ros041 from "../../../assets/ros04 1.png";
import { useState } from "react";

export const IdentidadDigitalUsuarioDummy = () => {
  const [esCargarImagen, setEsCargarImagen] = useState(true);
  const [esSeleccionAvatar, setEsSeleccionAvatar] = useState(false);
  const [esEpic, setEsEpic] = useState(false);
  const [esGlitch, setEsGlitch] = useState(false);
  const [esSolarize, setEsSolarize] = useState(false);
  const [esDots, setEsDots] = useState(false);
  const [esSelectProfile, setEsSelectProfile] = useState(false);
  const [esSolicitudCredito, setEsSolicitudCredito] = useState(false);
  const [esAtenea, setEsAtenea] = useState(false);
  const [esCerrarAtenea, setEsCerrarAtenea] = useState(false);
  const [imagenActual, setImagenActual] = useState("");
  const puedoAyudarte = "En que puedo ayudarte?";
  var i = 0;
  const resp =
    "Los pasivos corrientes son obligaciones a corto plazo que, en general, se espera que una empresa pague en el transcurso de un año. Algunos ejemplos de pasivos corrientes incluyen los siguientes: Salarios de los empleados. Préstamos a corto plazo.";
  var j = 0;
  const preg = "Qué son pasivos?";

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function seleccionarAvatar() {
    setEsCargarImagen(false);
    setEsSeleccionAvatar(true);
    setEsEpic(true);
    setImagenActual("Epic");
  }

  function next() {
    if (imagenActual === "Epic") {
      setEsEpic(false);
      setEsGlitch(true);
      setImagenActual("Glitch");
    } else if (imagenActual === "Glitch") {
      setEsGlitch(false);
      setEsSolarize(true);
      setImagenActual("Solarize");
    } else if (imagenActual === "Solarize") {
      setEsSolarize(false);
      setEsDots(true);
      setImagenActual("Dots");
    } else if (imagenActual === "Dots") {
      setEsDots(false);
      setEsEpic(true);
      setImagenActual("Epic");
    }
  }

  function before() {
    if (imagenActual === "Epic") {
      setEsEpic(false);
      setEsDots(true);
      setImagenActual("Dots");
    } else if (imagenActual === "Glitch") {
      setEsGlitch(false);
      setEsEpic(true);
      setImagenActual("Epic");
    } else if (imagenActual === "Solarize") {
      setEsSolarize(false);
      setEsGlitch(true);
      setImagenActual("Glitch");
    } else if (imagenActual === "Dots") {
      setEsDots(false);
      setEsSolarize(true);
      setImagenActual("Solarize");
    }
  }

  function selectProfile() {
    setEsSeleccionAvatar(false);
    setEsSelectProfile(true);
  }

  function continuar() {
    setEsSelectProfile(false);
    setEsSolicitudCredito(true);
  }

  async function atenea() {
    setEsSolicitudCredito(false);
    setEsAtenea(true);
    await delay(0);
    typeWriter();
  }

  function typeWriter() {
    const textAte = document.getElementById("textAyudar");
    const barraCont = document.getElementById("barraResp");
    const barraCons = document.getElementById("barraConsulta");
    if (barraCont) {
      barraCont.style.border = "none";
    }
    if (barraCons) {
      barraCons.style.border = "none";
    }
    if (i <= puedoAyudarte.length && textAte) {
      textAte.innerHTML += puedoAyudarte.charAt(i);
      i++;
      setTimeout(typeWriter, 30);
    }
  }

  function typeWriterContinuemos() {
    const inpPreg = document.getElementById(
      "preguntaInput"
    ) as HTMLInputElement;
    var valorPregunta = inpPreg.value;
    if (
      valorPregunta.toLowerCase() === "gracias" ||
      valorPregunta.toLowerCase() === "listo"
    ) {
      setEsAtenea(false);
      setEsCerrarAtenea(true);
    } else {
      const textCont = document.getElementById("textResp");
      const barraCont = document.getElementById("barraResp");
      const barraCons = document.getElementById("barraConsulta");
      const textPreg = document.getElementById(
        "textPregunta"
      ) as HTMLParagraphElement;
      const inpPreg = document.getElementById(
        "preguntaInput"
      ) as HTMLInputElement;
      if (inpPreg) {
        inpPreg.value = "";
      }
      if (textPreg) {
        textPreg.textContent = preg;
      }
      if (barraCont) {
        barraCont.style.borderLeft = "3px solid #00BCE2";
        barraCont.style.height = "1rem";
        barraCont.style.marginRight = "1rem";
      }
      if (barraCons) {
        barraCons.style.borderLeft = "3px solid #E28800";
        barraCons.style.height = "1rem";
        barraCons.style.marginRight = "1rem";
      }
      if (j <= resp.length && textCont) {
        textCont.innerHTML += resp.charAt(j);
        j++;
        setTimeout(typeWriterContinuemos, 30);
      }
    }
  }

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      {esCargarImagen && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(108.14% 102.63% at 102.67% 101.61%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              position: "absolute",
              top: "1rem",
              right: "1rem",
            }}
          ></button>
          <h4 style={{ color: "white", margin: "2rem 7rem" }}>
            Crearemos tu identidad digital
          </h4>
          <img style={{ width: "70%" }} src={ateneaGrande} alt="AteneaGrande" />
          <h4
            style={{
              color: "white",
              margin: "2rem 7rem 3rem 7rem",
              fontWeight: "lighter",
            }}
          >
            Carga una imagen desde tu dispositivo o tómate una selfie
          </h4>
          <button
            onClick={seleccionarAvatar}
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <UPLOAD style={{ scale: "0.8" }} />
          </button>
          <h5
            style={{ margin: ".5rem", color: "white", fontWeight: "lighter" }}
          >
            ADJUNTAR IMAGEN
          </h5>
        </div>
      )}
      {esSeleccionAvatar && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(101.01% 98.98% at 100% 98.98%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              position: "absolute",
              top: "1rem",
              right: "1rem",
            }}
          ></button>
          <h4
            style={{ color: "white", margin: "1rem 0", fontWeight: "lighter" }}
          >
            Vista actuals
          </h4>
          <img style={{ width: "30%" }} src={ros032} alt="ros032" />
          <h4 style={{ color: "white", margin: "2rem 7rem 3rem 7rem" }}>
            Selecciona el estilo de tu avatar
          </h4>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "15vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={before}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <LEFT />
              </button>
            </div>
            {esEpic && (
              <button
                onClick={selectProfile}
                style={{ background: "transparent", border: "none" }}
              >
                <img style={{ width: "100%" }} src={ros031} alt="ros031" />
              </button>
            )}
            {esGlitch && (
              <button style={{ background: "transparent", border: "none" }}>
                <img style={{ width: "100%" }} src={ros011} alt="ros011" />
              </button>
            )}
            {esSolarize && (
              <button style={{ background: "transparent", border: "none" }}>
                <img style={{ width: "100%" }} src={ros021} alt="ros021" />
              </button>
            )}
            {esDots && (
              <button style={{ background: "transparent", border: "none" }}>
                <img style={{ width: "100%" }} src={ros041} alt="ros041" />
              </button>
            )}
            <div
              style={{
                width: "15vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={next}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <RIGHT />
              </button>
            </div>
          </div>
          {esEpic && (
            <h4
              style={{
                color: "white",
                margin: "1rem 0",
                fontWeight: "lighter",
              }}
            >
              Epic
            </h4>
          )}
          {esGlitch && (
            <h4
              style={{
                color: "white",
                margin: "1rem 0",
                fontWeight: "lighter",
              }}
            >
              Glitch
            </h4>
          )}
          {esSolarize && (
            <h4
              style={{
                color: "white",
                margin: "1rem 0",
                fontWeight: "lighter",
              }}
            >
              Solarize
            </h4>
          )}
          {esDots && (
            <h4
              style={{
                color: "white",
                margin: "1rem 0",
                fontWeight: "lighter",
              }}
            >
              Dots
            </h4>
          )}
        </div>
      )}
      {esSelectProfile && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(101.01% 98.98% at 100% 98.98%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              position: "absolute",
              top: "1rem",
              right: "1rem",
            }}
          ></button>
          <h4 style={{ color: "white", margin: "4rem 0 1rem 0" }}>
            Asi se verá tu identidad digital
          </h4>
          <img style={{ width: "50%" }} src={ros031} alt="ros031" />
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="NOMBRES"
            type="text"
            value="ELIANA"
          />
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="APELLIDOS"
            type="text"
            value="MONTERO SANIN"
          />
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="TIPO DE DOCUMENTO"
            type="text"
            value="CEDULA DE CIUDADANIA"
          />
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="No. DE DOCUMENTO"
            type="number"
            value="107410865"
          />
          <div style={{ marginTop: "1rem" }}>
            <input id="Natural" type="radio" checked />
            <label style={{ color: "white" }} htmlFor="Natural">
              PERSONA NATURAL
            </label>
          </div>
          <button
            onClick={continuar}
            style={{
              backgroundColor: "black",
              border: "none",
              height: "3rem",
              width: "60%",
              color: "white",
              borderRadius: "30px",
              margin: "1rem",
            }}
          >
            CONFIRMAR
          </button>
        </div>
      )}
      {esSolicitudCredito && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(101.01% 98.98% at 100% 98.98%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <button
            onClick={atenea}
            style={{
              backgroundColor: "transparent",
              border: "none",
              position: "absolute",
              top: "1rem",
              right: "1rem",
            }}
          ></button>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              width: "100%",
              marginLeft: "20%",
            }}
          >
            <h3 style={{ color: "white", margin: "4rem 0 1rem 0" }}>
              Solucitud de crédito
            </h3>
            <h4
              style={{
                color: "white",
                margin: "0 0 1rem 0",
                fontWeight: "lighter",
              }}
            >
              Información contable
            </h4>
          </div>
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="PASIVOS"
            type="text"
          />
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="ACTIVOS"
            type="text"
          />
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="OTROS PASIVOS"
            type="text"
          />
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="OTROS INGRESOS"
            type="number"
          />
          <input
            className="entrada"
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "2rem",
              borderColor: "white",
              borderRadius: "10px",
              height: "2rem",
              width: "80%",
            }}
            placeholder="CAPITAL TOTAL"
            type="number"
          />
        </div>
      )}
      {esAtenea && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(209.61% 195.98% at 50% -10.68%, #00D8FF 0%, #002039 100%)",
          }}
        >
          <div
            id="atenea"
            className="atenea"
            style={{
              height: "95vh",
              width: "95vw",
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              backgroundColor: "white",
              borderRadius: "20px",
            }}
          >
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                position: "absolute",
                top: "1rem",
                right: "1rem",
              }}
            ></button>
            <div
              style={{
                height: "100vh",
                width: "70vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                margin: "auto",
                textAlign: "start",
              }}
            >
              <div style={{ display: "flex" }}>
                <h1
                  style={{
                    fontSize: "3rem",
                    color: "#00BCE2",
                    fontWeight: "bolder",
                    margin: "auto 0 0 0",
                  }}
                >
                  Hola!
                </h1>
                <h3 style={{ margin: "auto 0 0.5rem 0.5rem" }}>Eliana</h3>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    borderLeft: "3px solid #00BCE2",
                    height: "1rem",
                    marginRight: "1rem",
                  }}
                ></div>
                <p
                  id="textAyudar"
                  style={{
                    fontWeight: "lighter",
                    margin: "0 0 1rem 0",
                    fontSize: "0.8rem",
                  }}
                ></p>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  id="barraConsulta"
                  style={{
                    borderLeft: "3px solid #E28800",
                    height: "1rem",
                    marginRight: "1rem",
                  }}
                ></div>
                <p
                  id="textPregunta"
                  style={{
                    fontWeight: "lighter",
                    margin: "0 0 1rem 0",
                    fontSize: "0.8rem",
                  }}
                ></p>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  id="barraResp"
                  style={{
                    borderLeft: "3px solid #00BCE2",
                    height: "1rem",
                    marginRight: "1rem",
                  }}
                ></div>
                <p
                  id="textResp"
                  style={{
                    fontWeight: "lighter",
                    margin: "0",
                    fontSize: "0.8rem",
                  }}
                ></p>
              </div>
              <input
                id="preguntaInput"
                onBlur={typeWriterContinuemos}
                className="placeholder"
                type="text"
                placeholder="Escribe aquí tu consulta"
                style={{
                  backgroundColor: "#00BCE2",
                  marginTop: "3rem",
                  width: "100%",
                  borderRadius: "20px",
                  color: "white",
                  padding: "10px",
                }}
              ></input>
            </div>
          </div>
        </div>
      )}
      {esCerrarAtenea && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(181.87deg, #00D8FF -1.01%, #011A2E 100.65%)",
          }}
        ></div>
      )}
    </div>
  );
};
