import React, {useState} from "react";
import "./QuestionText.scss";
import {TextField2} from './TextField2/TextField2'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
function QuestionText() {
const [errorInput, setErrorInput] = useState(true)
  return (
    <div className="question-texts">
      <div className="mini-nav"> 
      <p>Question Text</p>
      </div>
      <div className="container-box">
      <Box sx={{ flexGrow: 2 }}>
          {/* ----------------INPUT1---------------------- */}
          <Grid container spacing={1} style={{    placeContent: 'space-between'}}>
            <Grid >
              <div>
                <p>Inactive</p>
                <TextField2 />
              </div>

              <div>
                {" "}
                <p>Focus</p>
                <TextField2 />
              </div>
            </Grid>
            {/* ----------------INPUT2---------------------- */}

            <Grid >
              <div>
                <p>Active</p>
                <TextField2/>
              </div>
              <div>
                <p>Error</p>
                <TextField2 errorInput={errorInput} setErrorInput={setErrorInput}/>
              </div>
            </Grid>
            {/* ----------------INPUT3---------------------- */}
            <div className="last-col">
              <p>Layout</p>
              <Grid item xs={3}>
                <div>
                  <TextField2 />
                </div>
                <div style={{marginTop:'2em'}}>
                  <TextField2/>
                </div>
              </Grid>
            </div>
          </Grid>
        </Box>
      </div>
     
    </div>
  );
}
export default QuestionText;
