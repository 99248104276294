import * as rax from "retry-axios";
import axios from "axios";
import { commonError, fatalError, infoMessage } from "./errorHandler";
import Form from "./dtos/Form";
import WelcomeFormScreen2 from ".././Dummies/InitialForm/WelcomeFormScreen2";
import InitialForm2 from ".././Dummies/InitialForm/InitialForm2";
import FinalForm2 from ".././Dummies/InitialForm/FinalForm2";
// rax.attach();

// const server = "http://localhost:8083";
const server = "https://dummies-back.anteia.co";

export default class DummiesApi {
  async getForm(id) {
    let url = server + "/form/" + id;
    try {
      var res = await axios({
        method: "GET",
        url: url,
      });
      return res.data;
    } catch (e) {
      console.log(e);
      infoMessage("Error: " + e);
    }
  }

  async getFormByFilledFormId(id) {
    let url = server + "/form/getByFilledFormId/" + id;
    try {
      var res = await axios({
        method: "GET",
        url: url,
      });
      return res.data;
    } catch (e) {
      console.log(e);
      infoMessage("Error: " + e);
    }
  }

  async sendForm(data, formId) {
    console.log("Sending Data!");
    console.log(data);
    let req = {
      data: data,
      formId: formId,
    };
    let url = server + "/filledForm/createFilledForm";
    try {
      let res = await axios({
        method: "POST",
        url: url,
        data: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res.data);
      return res.data;
    } catch (e) {}
  }

  async updateForm(data, filledFormId) {
    console.log("Updating Data!");
    console.log(data);
    let req = {
      data: data,
    };
    let url = server + "/filledForm/updateForm/" + filledFormId;
    try {
      let res = await axios({
        method: "PUT",
        url: url,
        data: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res.data);
      return res.data;
    } catch (e) {}
  }

  async addCoreIdData(regId, filledFormId) {
    console.log("Adding CoreId Data");
    let url = server + "/coreId/addCoreIdData/" + filledFormId + "/" + regId;
    try {
      let res = await axios({
        method: "GET",
        url: url,
      });
      console.log(res);
    } catch (e) {}
  }

  async getCoreIdLink(formId) {
    let url = server + "/coreId/getLink/" + formId;
    try {
      var res = await axios({
        method: "GET",
        url: url,
      });
      console.log(res.data);
      return res.data;
    } catch (e) {
      console.log(e);
      infoMessage("Error: " + e);
    }
  }

  async getCoreIdLinkGeneric2(redUrl, params, formId) {
    let url = server + "/coreId/getGenericLink";
    let req = {
      redirectionUrl: redUrl,
      params: params,
      formId: formId,
    };
    try {
      let res = await axios({
        method: "POST",
        url: url,
        data: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res.data);
      return res.data;
    } catch (e) {}
  }

  async getCoreIdLinkGeneric(req) {
    let url = server + "/coreId/getGenericLink";

    try {
      let res = await axios({
        method: "POST",
        url: url,
        data: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res.data);
      return res.data;
    } catch (e) {}
  }

  async getCoreIdLinkAlt(formId) {
    let url = server + "/coreId/getLinkAlt/" + formId;
    try {
      var res = await axios({
        method: "GET",
        url: url,
      });
      console.log(res.data);
      return res.data;
    } catch (e) {
      console.log(e);
      infoMessage("Error: " + e);
    }
  }

  async getFilledForm(id) {
    let url = server + "/filledForm/getById/" + id;
    try {
      var res = await axios({
        method: "GET",
        url: url,
      });
      console.log(res.data);
      return res.data;
    } catch (e) {
      console.log(e);
      infoMessage("Error: " + e);
    }
  }

  async getAuthRedirect(filledFormId, regId) {
    console.log("GET AUTH REDIRECT");
    let url = server + "/coreId/getAuthRedirect/" + filledFormId + "/" + regId;

    try {
      var res = await axios({
        method: "GET",
        url: url,
      });
      console.log(res.data);
      return res.data;
    } catch (e) {
      console.log(e);
      infoMessage("Error: " + e);
    }
  }
  async getAuthRedirectGeneral(regId, urlt, meta) {
    console.log("Sending Data!");
    let req = {
      url: urlt,
      metadata: meta,
    };
    let url = server + "/coreId/getAuthRedirectWithUrl/" + regId;
    try {
      let res = await axios({
        method: "POST",
        url: url,
        data: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res.data);
      return res.data;
    } catch (e) {}
  }

  async getAuthProcess(authId, filledFormId) {
    console.log("GET AUTH PROCESS");
    let url = server + "/coreId/getAuthProcess/" + filledFormId + "/" + authId;
    try {
      var res = await axios({
        method: "GET",
        url: url,
      });
      console.log(res.data);
      return res.data;
    } catch (e) {
      console.log(e);
      infoMessage("Error: " + e);
    }
  }

  async sendFinalEmail(filledFormId) {
    console.log("GET SEND FINAL EMAIL");
    let url = server + "/coreId/sendFinalEmail/" + filledFormId;
    try {
      var res = await axios({
        method: "GET",
        url: url,
      });
      console.log(res);
      return res;
    } catch (e) {
      console.log(e);
      infoMessage("Error: " + e);
    }
  }

  async sendEmailIns(
    email,
    nombreAsegurado,
    identificacionAsegurado,
    fechaDesde,
    fechaHasta,
    nombreTomador,
    identificacionTomador,
    montoAsegurado,
    prima
  ) {
    console.log("GET SEND FINAL EMAIL");
    let url = server + "/coreId/sendInsEmail";

    var body = {
      email: email,
      nombreAsegurado: nombreAsegurado,
      identificacionAsegurado: identificacionAsegurado,
      fechaDesde: fechaDesde,
      fechaHasta: fechaHasta,
      nombreTomador: nombreTomador,
      identificacionTomador: identificacionTomador,
      montoAsegurado: montoAsegurado,
      prima: prima,
    };

    try {
      var res = await axios({
        method: "POST",
        url: url,
        data: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res);
      return res;
    } catch (e) {
      console.log(e);
      infoMessage("Error: " + e);
    }
  }

  async getElectronicSigRedirect(filledFormId) {
    console.log("GET SIGNATURE Redirect URL");
    let url = server + "/electronicSignature/getUrl/" + filledFormId;
    try {
      var res = await axios({
        method: "GET",
        url: url,
      });
      console.log(res);
      return res.data;
    } catch (e) {
      console.log(e);
      infoMessage("Error: " + e);
    }
  }

  async getFormMok(id) {
    return {
      clientId: "Cid",
      fields: [
        {
          id: "name",
          title: "Nombres",
          _t: "co.anteia.dtos.FormFieldText",
          autoLoadable: true,
          dataa: "",
          description: "",
          role: "",
          sourceId: "",
          type: "",
          userEditable: true,
        },
        {
          id: "lastName",
          title: "Apellidos",
          _t: "co.anteia.dtos.FormFieldDateTime",
          autoLoadable: true,
          dataa: "",
          description: "",
          role: "",
          sourceId: "",
          type: "FormFieldDateTime",
          userEditable: true,
        },
        {
          id: "document",
          title: "# Documento",
          _t: "co.anteia.dtos.FormFieldText",
          autoLoadable: true,
          dataa: "",
          description: "",
          role: "",
          sourceId: "",
          type: "",
          userEditable: true,
        },
        {
          id: "email",
          title: "Correo electrónico",
          _t: "co.anteia.dtos.FormFieldRegexText",
          autoLoadable: true,
          dataa: "",
          description: "",
          regex: "hola",
          role: "",
          sourceId: "",
          type: "FormFieldRegexText",
          userEditable: true,
        },
        {
          id: "phone",
          title: "# Celular",
          _t: "co.anteia.dtos.FormFieldRegexText",
          autoLoadable: true,
          dataa: "",
          description: "",
          regex: "hola",
          role: "",
          sourceId: "",
          type: "FormFieldRegexText",
          userEditable: true,
        },
        {
          id: "pregunta1",
          title: "Pregunta 1",
          _t: "co.anteia.dtos.FormFieldRegexText",
          autoLoadable: false,
          dataa: "",
          description: "",
          regex: "hola",
          role: "",
          sourceId: "",
          type: "FormFieldRegexText",
          userEditable: true,
        },
        {
          id: "pregunta2",
          title: "Pregunta 2",
          _t: "co.anteia.dtos.FormFieldRegexText",
          autoLoadable: false,
          dataa: "",
          description: "",
          regex: "hola",
          role: "",
          sourceId: "",
          type: "FormFieldRegexText",
          userEditable: true,
        },
      ],
      name: "Hola",
      roles: ["ADMIN"],
      smartContract: false,
      usesCoreId: true,
      usesPay: true,
    };
  }

  async getFilledFormMok(id) {
    return {
      clientId: "Cid",
      fields: [
        {
          id: "name",
          title: "Nombres",
          _t: "co.anteia.dtos.FormFieldText",
          autoLoadable: true,
          dataa: "Juan",
          description: "",
          role: "",
          sourceId: "",
          type: "",
          userEditable: false,
        },
        {
          id: "lastName",
          title: "Apellidos",
          _t: "co.anteia.dtos.FormFieldDateTime",
          autoLoadable: true,
          dataa: "Zambrano",
          description: "",
          role: "",
          sourceId: "",
          type: "FormFieldDateTime",
          userEditable: false,
        },
        {
          id: "document",
          title: "# Documento",
          _t: "co.anteia.dtos.FormFieldText",
          autoLoadable: true,
          dataa: "1020817",
          description: "",
          role: "",
          sourceId: "",
          type: "",
          userEditable: false,
        },
        {
          id: "email",
          title: "Correo electrónico",
          _t: "co.anteia.dtos.FormFieldRegexText",
          autoLoadable: true,
          dataa: "juan@anteia.co",
          description: "",
          regex: "hola",
          role: "",
          sourceId: "",
          type: "FormFieldRegexText",
          userEditable: false,
        },
        {
          id: "phone",
          title: "# Celular",
          _t: "co.anteia.dtos.FormFieldRegexText",
          autoLoadable: true,
          dataa: "",
          description: "",
          regex: "hola",
          role: "",
          sourceId: "",
          type: "FormFieldRegexText",
          userEditable: false,
        },
        {
          id: "pregunta1",
          title: "Pregunta 1",
          _t: "co.anteia.dtos.FormFieldRegexText",
          autoLoadable: false,
          dataa: "",
          description: "",
          regex: "hola",
          role: "",
          sourceId: "",
          type: "FormFieldRegexText",
          userEditable: true,
        },
        {
          id: "pregunta2",
          title: "Pregunta 2",
          _t: "co.anteia.dtos.FormFieldRegexText",
          autoLoadable: false,
          dataa: "",
          description: "",
          regex: "hola",
          role: "",
          sourceId: "",
          type: "FormFieldRegexText",
          userEditable: true,
        },
      ],
      name: "Hola",
      roles: ["ADMIN"],
      smartContract: false,
      usesCoreId: true,
      usesPay: true,
    };
  }
}
