import React from "react";
import "./Requirements.scss";
import img from "./../../assets/Vector-5.png";
import { Button } from "../../utils/Buttons/Buttons";
import { useHistory } from "react-router-dom";

function Requirements(props) {
  const history = useHistory();
  
  if(props.ni===""){
    history.push("/enter"+ history.location.search);
  }
  return (
    <div className="requirements">
      <p className="title">
        REQUISITOS<br></br>PREVIOS
      </p>
      <img src={img} alt="img" />
      <p>
        Este vehículo cumple con todos los <br></br>requisitos para su traspaso{" "}
        <br></br>de propiedad
      </p>
      <div className="btn-req">
        <Button text="CONTINUAR" width="177px" height="5vh"  onClick={() =>props.redirectmfa()}/>
      </div>
    </div>
  );
}
export default Requirements;
