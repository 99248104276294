import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useEffect, useState } from "react";
import { getColorFromCode, updateData } from "../api/magic_forms_2_api";
import useQuery from "../../utils/UseQuery";
import { ClipLoader } from "react-spinners";
import FormField from "../dtos/FormField";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import { infoMessage } from "../../api/errorHandler";
import { ConstructionOutlined } from "@mui/icons-material";
import { TextFieldStyleDummies } from "../../utils/TextFieldStyle/TextFieldStyle";
import {
  commitCoreId,
  create,
  getColorsByFlowId,
  getColorsByProcessId,
  getCoreIdLink,
  getFlowConfigByProcessId,
  getMagicLink,
} from "../api/FlowApi";

const NewDummiesCoreIdCallback = () => {
  //query params
  const query = useQuery();
  const processId = query.get("processId") ?? "error";
  const regId = query.get("registrationId") ?? "error";
  const [needsMagicForms, setNeedsMagicForms] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  //State selectors

  //local states
  const [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);

  //get colors
  async function getColors() {
    let resp = await getColorsByProcessId(processId);
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
  }

  //Commit coreid
  async function commitCoreIdStuff() {
    console.log("ProcessId: " + processId);
    console.log("regId: " + regId);
    await commitCoreId(processId, regId);
  }

  //load Data
  async function loadData() {
    await getColors();
    let config = await getFlowConfigByProcessId(processId);
    if (config.hasMagicForms) {
      setNeedsMagicForms(true);
    }
    await commitCoreIdStuff()
    setDataLoaded(true);
  }

  async function redirectFinal() {
    window.location.href = "https://forms.gle/oTwVa7FxLtAJ2SaW9";
  }

  //Get Magic Url
  async function getMfUrl() {
    try {
      let resp = await getMagicLink(processId);
      // await infoMessage(resp.url);
      window.location.href = resp.url;
    } catch (e: any) {
      infoMessage("Error :c");
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  if (!dataLoaded) {
    return (
      <div
        style={{
          background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div style={{ marginTop: "20vh" }}>
          <ClipLoader loading={true} size={150} color={"#FFFFFF"} />
          <h1
            className="txt-label-title"
            style={{ fontSize: "", marginBottom: "50px" }}
          >
            Espera un momento
          </h1>
        </div>
      </div>
    );
  }
  //Render
  if (needsMagicForms) {
    return (
      <div
        style={{
          background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
          }}
        >
          <h1
            className="txt-label-title"
            style={{ fontSize: "", marginBottom: "50px" }}
          >
            ¡Excelente!
          </h1>
          <h1
            className="txt-label"
            style={{ fontSize: "", marginBottom: "50px" }}
          >
            Tu identidad digital ha sido creada
          </h1>
          <h1
            className="txt-label"
            style={{ fontSize: "", marginBottom: "50px" }}
          >
            Continuemos con tu proceso
          </h1>
          <div
            style={{
              marginTop: "40px",
            }}
          ></div>
          <ButtonStyle
            onClick={() => {
              getMfUrl();
            }}
            text="Continuar"
            width="177px"
            height="5vh"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
          }}
        >
          <h1
            className="txt-label-title"
            style={{ fontSize: "", marginBottom: "50px" }}
          >
            ¡Excelente!
          </h1>
          <h1
            className="txt-label"
            style={{ fontSize: "", marginBottom: "50px" }}
          >
            Tu proceso ha terminado exitosamente
          </h1>

          <div
            style={{
              marginTop: "40px",
            }}
          ></div>
          <ButtonStyle
            onClick={() => {
              redirectFinal();
            }}
            text="Terminar"
            width="177px"
            height="5vh"
          />
        </div>
      </div>
    );
  }
};

export default NewDummiesCoreIdCallback;
